import { Fragment } from 'react';
import { CircleMarker, CircleMarkerProps } from 'react-leaflet';
import { LeafletEventHandlerFnMap } from 'leaflet';
import { FloorPlanLocationListType } from '../../@types';

const CircleMapMarker = ({
  floorPlanLocations,
  selectedLocationID,
  onMarkerClick,
}: {
  floorPlanLocations: FloorPlanLocationListType[];
  selectedLocationID?: string;
  onMarkerClick?: (locationID: string) => void;
}) => {
  return (
    <Fragment>
      {floorPlanLocations.length > 0 &&
        floorPlanLocations.map((el) => {
          let x = el.x;
          let y = el.y;
          let pathOptionsProps: Partial<CircleMarkerProps> = {
            fillColor: el.colorCode,
            fillOpacity: 0.8,
            color: undefined,
          };

          if (selectedLocationID === el.locationID) {
            pathOptionsProps.fillOpacity = 0.7;
            pathOptionsProps.color = '#098bf4';
            pathOptionsProps.weight = 3;
            pathOptionsProps.opacity = 1;
          }

          let eventHandlers: LeafletEventHandlerFnMap = {};
          if (onMarkerClick) {
            eventHandlers.click = () => onMarkerClick(el.locationID);
          }

          return (
            <Fragment key={el.locationID}>
              <CircleMarker
                pathOptions={{ ...pathOptionsProps }}
                eventHandlers={{ ...eventHandlers }}
                center={[y ?? 0, x ?? 0]}></CircleMarker>
            </Fragment>
          );
        })}
    </Fragment>
  );
};

export default CircleMapMarker;
