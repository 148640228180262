import { Button, Col, Popover, Row, Table } from 'antd';
import axios, { AxiosResponse } from 'axios';
import { Fragment, Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import update from 'immutability-helper';
import { FaImage, FaTachometerAlt } from 'react-icons/fa';
import { v4 } from 'uuid';
import { Link } from 'react-router-dom';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { handleSorting } from '../utils';
import { apiCall } from '../api-services/api';
import { locationApi } from '../api-services/api-list';
import { StateType } from '../components/LocationList/helpers';
import { updateToken } from '../redux/actions/auth.actions';
import { LocationListType, UserDataType } from '../@types';
import { handleNotification } from '../utils/notification-handler';
import { locationRoutes } from '../routes/routes-list';

import withBreakpoint from '../HOC/withBreakpoint';
import LocationMap from '../components/LocationList/LocationMap';
import { handleTableSearch } from '../utils';
import {
  AntdTableColumnsType,
  ApiResponseDataType,
  BooleanObjectType,
  ReduxStoreType,
} from '../@types';
import FilterInput from '../components-shared/FilterInput';
import {
  saveFilterCheckboxData,
  saveSearchData,
} from '../redux/actions/location.actions';
import DownloadModal from '../components-shared/DownloadModal';
import SettingsModal from '../components-shared/SettingsModal';

// import locations from '../data/locations.json';

// const tempLocations: any = locations;
// let tempData: LocationListType[] = [];
// if (tempLocations?.data) {
//   tempData = tempLocations.data.map((el: LocationListType) => ({
//     ...el,
//     uuid: v4(),
//   }));
// }

type PropsType = RouteComponentProps &
  PropsFromRedux & {
    userData: Partial<UserDataType>;
    screens: Partial<Record<Breakpoint, boolean>>;
  };

class LocationList extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      locationList: [],
      loading: this.initLocationList.length === 0,
      showSettingsDialog: false,
      partnerDataList:
        this.initLocationList.length > 0
          ? Array.from(new Set(this.initLocationList.map((el) => el.partnerID)))
          : [],

      selectedLocationID: '',
    };
  }

  initLocationList: LocationListType[] = [];
  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  mapAutoUpdate: NodeJS.Timeout | undefined = undefined;

  componentDidMount() {
    this._isMounted = true;

    if (this.initLocationList.length === 0) {
      this.fetchLocationList();
    }

    this.mapAutoUpdate = setInterval(() => {
      this.fetchLocationList();
    }, 10 * 60000);
  }

  componentWillUnmount() {
    if (this.mapAutoUpdate) {
      clearInterval(this.mapAutoUpdate);
    }
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  fetchLocationList = async () => {
    const {
      userData,
      updateToken,
      filterCheckBoxData,
      searchData,
      saveFilterCheckboxData,
    } = this.props;

    let stateData: Partial<StateType> = {};
    try {
      const { url, method, contentType } = locationApi.getLocations();
      const response: AxiosResponse<ApiResponseDataType> | undefined =
        await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
      const result = response?.data;

      updateToken(result);

      if (result?.data) {
        let tempLocations: LocationListType[] = result.data.map(
          (el: LocationListType) => ({ ...el, uuid: v4() })
        );
        this.initLocationList = update(this.initLocationList, {
          $set: tempLocations,
        });
        const tempPartner = Array.from(
          new Set(tempLocations.map((el) => el.partnerID))
        );

        if (
          filterCheckBoxData?.checkBoxDetails &&
          Object.keys(filterCheckBoxData.checkBoxDetails).length > 0
        ) {
          tempLocations = tempLocations.filter((item) => {
            if (
              filterCheckBoxData.checkBoxDetails &&
              filterCheckBoxData.checkBoxDetails[item.partnerID] === true
            ) {
              return item;
            }
            return null;
          });
        }

        if (
          filterCheckBoxData.checkBoxDetails &&
          Object.keys(filterCheckBoxData.checkBoxDetails).length === 0 &&
          tempPartner.length > 0
        ) {
          let tempCheckbox: BooleanObjectType = {};
          tempPartner.forEach((el) => {
            tempCheckbox = update(tempCheckbox, { [el]: { $set: true } });
          });

          saveFilterCheckboxData({
            filterCheckBoxData: {
              isAllSelected: true,
              checkBoxDetails: tempCheckbox,
            },
          });
        }

        if (searchData) {
          const columns: any[] = this.getTableColumns()
            .map((el) => el.dataIndex)
            .filter((el) => el);
          tempLocations = handleTableSearch({
            data: tempLocations,
            columnList: columns,
            searchData,
          });
        }

        stateData = update(stateData, {
          locationList: { $set: tempLocations },
          partnerDataList: { $set: tempPartner },
        });
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error?.data);
      updateToken(error?.data);
    }

    stateData = update(stateData, { loading: { $set: false } });

    this.handleState({ ...stateData });
  };

  handleSearchFilter = (value: string) => {
    let stateData: Partial<StateType> = {
      locationList: [...this.initLocationList],
    };

    const { saveSearchData } = this.props;
    saveSearchData({ searchData: value });

    if (value && stateData.locationList) {
      let tempData = [...stateData.locationList];
      const columns: any[] = this.getTableColumns()
        .map((el) => el.dataIndex)
        .filter((el) => el);

      tempData = handleTableSearch({
        data: tempData,
        columnList: columns,
        searchData: value,
      });

      stateData = update(stateData, { locationList: { $set: tempData } });
    }

    this.handleState({
      ...stateData,
    });
  };

  handleCheckboxFilter = (filterCheckBox: {
    isAllSelected: boolean;
    checkBoxDetails: BooleanObjectType;
  }) => {
    const { saveFilterCheckboxData } = this.props;
    saveFilterCheckboxData({
      filterCheckBoxData: filterCheckBox,
    });
    let filteredData = [...this.initLocationList];

    let checkBoxDetails = filterCheckBox?.checkBoxDetails;
    let isAllSelected = filterCheckBox?.isAllSelected;

    let stateData: Partial<StateType> = {};

    if (isAllSelected === true) {
      stateData = update(stateData, {
        locationList: { $set: filteredData },
      });
    } else if (checkBoxDetails) {
      filteredData = filteredData.filter((item) => {
        if (checkBoxDetails[item.partnerID] === true) {
          return item;
        }
        return null;
      });
      stateData = update(stateData, {
        locationList: { $set: filteredData },
      });
    }

    this.handleState({ ...stateData });
  };

  openSettingsDialog = (locationID?: string) => {
    this.handleState({
      showSettingsDialog: true,
      selectedLocationID: locationID,
    });
  };

  closeSettingsDialog = () => {
    this.handleState({
      showSettingsDialog: false,
      selectedLocationID: '',
    });
  };

  onSaveSettingsDialog = () => {
    const { selectedLocationID } = this.state;
    const { history, location } = this.props;
    if (location.pathname === locationRoutes.list()) {
      history.push({
        pathname: locationRoutes.carousel({
          locationID: selectedLocationID,
        }),
      });
    }
  };

  getTableColumns = (): AntdTableColumnsType<LocationListType>[] => {
    const { showSettingsDialog } = this.state;
    const { userData } = this.props;
    return [
      {
        title: 'Location Name',
        key: 'name',
        dataIndex: 'name',
        sorter: (a: LocationListType, b: LocationListType) =>
          handleSorting(a.name, b.name),
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'ascend',
        ellipsis: true,
        render: (text: string, record: LocationListType, index: number) => {
          return (
            <Link
              to={{
                pathname: locationRoutes.dashboard({
                  locationID: record.locationID,
                }),
              }}>
              {text}
            </Link>
          );
        },
      },
      {
        title: 'Location ID',
        key: 'locationID',
        dataIndex: 'locationID',
        sorter: (a: LocationListType, b: LocationListType) =>
          handleSorting(a.locationID, b.locationID),
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
      },
      {
        title: 'Description',
        key: 'friendlyName',
        dataIndex: 'friendlyName',
        sorter: (a: LocationListType, b: LocationListType) =>
          handleSorting(a.friendlyName, b.friendlyName),
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
      },
      {
        title: 'Last Connected',
        key: 'lastContact',
        dataIndex: 'lastContact',
        ellipsis: true,
      },
      {
        title: 'Action',
        key: 'action',
        render: (text: string, record: LocationListType, index: number) => {
          return (
            <Fragment>
              <Row align="middle" gutter={[16, 0]}>
                {record?.rights?.length > 0 &&
                  record.rights.includes('CanDownload') && (
                    <Col xs={24} lg={8} className="text-center">
                      <DownloadModal
                        locationID={record.locationID}
                        userData={userData}
                      />
                    </Col>
                  )}
                <Col xs={24} lg={8} className="text-center">
                  <Link
                    to={{
                      pathname: locationRoutes.dashboard({
                        locationID: record.locationID,
                      }),
                    }}>
                    <FaTachometerAlt
                      size="1.2em"
                      style={{ color: '#007bff' }}
                    />
                  </Link>
                </Col>
                <Fragment>
                  {!showSettingsDialog && (
                    <Popover
                      overlayStyle={{ width: '250px' }}
                      content={
                        <PopupContent
                          record={record}
                          onClick={() =>
                            this.openSettingsDialog(record.locationID)
                          }
                        />
                      }
                      title="Choose View"
                      trigger="click">
                      <Col
                        xs={24}
                        lg={8}
                        style={{ cursor: 'pointer' }}
                        className="text-center">
                        <FaImage size="1.2em" style={{ color: '#007bff' }} />
                      </Col>
                    </Popover>
                  )}
                </Fragment>
              </Row>
            </Fragment>
          );
        },
      },
    ];
  };

  render() {
    const { loading, locationList, partnerDataList, showSettingsDialog } =
      this.state;
    const { screens, filterCheckBoxData, searchData } = this.props;

    return (
      <Fragment>
        <Row className="p-3">
          <Col xs={24}>
            <Row
              className=""
              style={{ background: '#fff', minHeight: '100vh' }}>
              <Col lg={12} style={{ overflowX: 'auto' }}>
                <Row className="py-2 pl-1">
                  <Col xs={24}>
                    <FilterInput
                      dataList={partnerDataList}
                      handleCheckboxFilter={this.handleCheckboxFilter}
                      handleSearchFilter={this.handleSearchFilter}
                      loading={loading}
                      filterCheckBoxData={filterCheckBoxData}
                      searchData={searchData}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <Table
                      bordered
                      dataSource={locationList}
                      loading={{ size: 'large', spinning: loading }}
                      columns={this.getTableColumns()}
                      pagination={{
                        pageSize: 10,
                        showSizeChanger: false,
                        total: locationList.length,
                        size: screens.xs ? undefined : 'default',
                        simple: screens.xs ? true : false,
                      }}
                      rowKey={'uuid'}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={12} className="pl-lg-1">
                <LocationMap dataList={locationList} />
              </Col>
            </Row>

            {showSettingsDialog && (
              <SettingsModal
                showModal={showSettingsDialog}
                closeModal={this.closeSettingsDialog}
                onSaveSettingsDialog={this.onSaveSettingsDialog}
              />
            )}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

function mapStateToProps(state: ReduxStoreType) {
  return {
    filterCheckBoxData: state.location.filterCheckBoxData,
    searchData: state.location.searchData,
  };
}

const mapDispatch = {
  updateToken: updateToken,
  saveFilterCheckboxData: saveFilterCheckboxData,
  saveSearchData: saveSearchData,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withBreakpoint(LocationList));

function PopupContent({
  onClick,
  record,
}: {
  onClick: () => void;
  record: LocationListType;
}) {
  const history = useHistory();
  return (
    <Fragment>
      <Row justify="center">
        <Col xs={12} className="text-center">
          <Button
            type="primary"
            size="small"
            htmlType="button"
            onClick={() =>
              history.push({
                pathname: locationRoutes.kiosk({
                  locationID: record.locationID,
                }),
              })
            }>
            Kiosk
          </Button>
        </Col>
        <Col xs={12} className="text-center">
          <Button
            type="primary"
            size="small"
            htmlType="button"
            onClick={onClick}>
            Carousel
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}
