import { TimeUnit } from 'chart.js';

import {
  inputFieldFormElements,
  InputFieldsFormElementsType,
} from '../../../components-shared/ChartInputFields/helpers';
import { BooleanObjectType, DefaultObjectType } from '../../../@types';
import { LocationListType } from '../../../@types';

export type StateType = {
  isLoading: boolean;
  locationList: LocationListType[];
  initLocationList: LocationListType[];
  showTable: boolean;
  isCheckboxAll: boolean;
  partnerDataList: string[];
  chartData: DefaultObjectType;
  sensorChartData: DefaultObjectType;
  formElements: InputFieldsFormElementsType;
  loadingRefresh: boolean;
  chartType: TimeUnit;
  searchFilterCheckBoxData: {
    checkBoxDetails?: BooleanObjectType;
    isAllSelected: boolean;
  };
  searchData: string;
};

export const initState: StateType = {
  isLoading: true,
  locationList: [],
  initLocationList: [],
  showTable: true,
  isCheckboxAll: false,
  partnerDataList: [],
  formElements: { ...inputFieldFormElements },
  chartData: {},
  loadingRefresh: false,
  chartType: 'hour',
  searchFilterCheckBoxData: {
    checkBoxDetails: {},
    isAllSelected: true,
  },
  searchData: '',
  sensorChartData: {},
};
