import { Component, Fragment } from 'react';
import { Row, Col, Checkbox, Table, Button } from 'antd';
import { RouteComponentProps } from 'react-router';
import update from 'immutability-helper';
import { FaTable, FaTimes } from 'react-icons/fa';
import { Line as LineChart } from 'react-chartjs-2';
import axios from 'axios';

import withBreakpoint from '../HOC/withBreakpoint';

import 'chartjs-plugin-zoom';

import {
  initState,
  StateType,
} from '../components/MultipleLocationsCharts/helpers';
import {
  AxiosHttpAllSettledResponsesType,
  LocationListType,
  UserDataType,
} from '../@types';

import cssStyles from '../components/MultipleLocationsCharts/styles/mutipleLocationsCharts.module.scss';
import AntdCard from '../components-shared/AntdCard';
import FilterInput from '../components-shared/FilterInput';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  AntdTableColumnsType,
  BooleanObjectType,
  ChartParamsType,
  DefaultObjectType,
} from '../@types';
import {
  getZuluFormatUTC,
  handleSorting,
  handleTableSearch,
  manageChartData,
} from '../utils';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { httpCallAllSettled, httpCallErrorHandling } from '../api-services/api';
import { locationApi } from '../api-services/api-list';
import { v4 } from 'uuid';
import { handleNotification } from '../utils/notification-handler';
import ChartInputFields from '../components-shared/ChartInputFields';
import { hasArrayValue, hasObjectKey } from '../shared/helpers';

type PropsType = RouteComponentProps & {
  userData: Partial<UserDataType>;
  screens: Partial<Record<Breakpoint, boolean>>;
};

type LineChartType = LineChart | null;

class MultipleLocationsCharts extends Component<PropsType, StateType> {
  _isMounted = false;
  chartRefs: LineChartType[] = [];
  axiosCancelSource = axios.CancelToken.source();
  constructor(props: PropsType) {
    super(props);

    this.state = {
      ...initState,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchLocations();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>, callback?: () => void) => {
    this._isMounted &&
      this.setState(
        (prev) => {
          return {
            ...prev,
            ...data,
          };
        },
        () => {
          callback?.();
        }
      );
  };

  fetchLocations = () => {
    const { userData } = this.props;
    const apiDetails = locationApi.getLocations();
    const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
      if (responses.length > 0) {
        if (responses[0].status === 'fulfilled') {
          let tempLocations: LocationListType[] = responses[0].value.data?.data;
          if (tempLocations) {
            tempLocations = tempLocations.map((el: LocationListType) => ({
              ...el,
              uuid: v4(),
            }));

            const tempPartner = Array.from(
              new Set(tempLocations.map((el) => el.partnerID))
            );

            let tempCheckbox: BooleanObjectType = {};
            if (tempPartner.length > 0) {
              tempPartner.forEach((el) => {
                tempCheckbox = update(tempCheckbox, { [el]: { $set: true } });
              });
            }

            this.handleState({
              isLoading: false,
              initLocationList: tempLocations,
              locationList: tempLocations,
              partnerDataList: tempPartner,
              searchFilterCheckBoxData: {
                isAllSelected: true,
                checkBoxDetails: tempCheckbox,
              },
            });
          }
        } else {
          httpCallErrorHandling(responses[0]);
        }
      }
    };

    httpCallAllSettled({
      requestConfig: [{ ...apiDetails }],
      headersConfig: {
        token: userData.token,
        // cancelToken: this.axiosCancelSource.token,
      },
      applyData: handleResponses,
    });
  };

  handleTableVisibility = () => {
    this.setState((prev) => ({
      ...prev,
      showTable: !prev.showTable,
    }));
  };

  handleSearchCheckboxFilter = (filterCheckBox: {
    isAllSelected: boolean;
    checkBoxDetails: BooleanObjectType;
  }) => {
    const { initLocationList } = this.state;

    let checkBoxDetails = filterCheckBox?.checkBoxDetails;
    let isAllSelected = filterCheckBox?.isAllSelected;
    let tempLocations = [...initLocationList];

    if (isAllSelected === false && checkBoxDetails) {
      tempLocations = initLocationList.filter((item) => {
        if (checkBoxDetails[item.partnerID] === true) {
          return item;
        }
        return null;
      });
    }

    this.handleState({
      locationList: tempLocations,
      searchFilterCheckBoxData: filterCheckBox,
    });
  };

  handleSearchFilter = (value: string) => {
    const { initLocationList } = this.state;
    let tempLocations = [...initLocationList];

    if (value && tempLocations) {
      let tempData = [...tempLocations];
      const columns: any[] = getTableColumns()
        .map((el) => el.dataIndex)
        .filter((el) => el);

      tempLocations = handleTableSearch({
        data: tempData,
        columnList: columns,
        searchData: value,
      });
    }

    this.handleState({
      locationList: tempLocations,
      searchData: value,
    });
  };

  handleCheckboxAll = () => {
    this.handleState({
      chartData: {},
      isCheckboxAll: false,
      sensorChartData: {},
    });
  };

  getChartParams = () => {
    let params: ChartParamsType = {};
    const { formElements } = this.state;

    if (formElements?.splitTime) {
      params = update(params, { split: { $set: formElements.splitTime } });
    }

    if (!formElements.switcher) {
      params = update(params, {
        from: {
          $set: getZuluFormatUTC(
            formElements.startDate,
            formElements.startTime
          ),
        },
        to: {
          $set: getZuluFormatUTC(formElements.endDate, formElements.endTime),
        },
      });
    } else {
      if (formElements.lastHours) {
        params = update(params, {
          lasthours: { $set: formElements.lastHours },
        });
      }
    }

    return params;
  };

  onRefreshClick = async () => {
    this.handleState({ loadingRefresh: true });
    const { userData } = this.props;
    const { chartData, chartType, formElements } = this.state;

    let tempChartData = { ...chartData };

    const tempTimeSeriesCollection: any[] = [];
    const tempDetailsCollection: any[] = [];
    const promiseCollection: any[] = [];

    const formData = this.getChartParams();

    let tempChartType = chartType;

    if (Number(formElements.lastHours) > 24 || formElements.switcher) {
      tempChartType = 'day';
    } else if (
      formElements.lastHours === '' ||
      Number(formElements.lastHours) <= 24
    ) {
      tempChartType = 'hour';
    }

    Object.keys(tempChartData).forEach(async (el) => {
      const apiDetails = locationApi.getLocationDetails(undefined, {
        locationID: el,
      });

      const apiDetails2 = locationApi.getLocationAverages(
        { ...formData },
        { locationID: el }
      );

      const headers: {
        'X-API-KEY': undefined | string;
        'Content-Type'?: string;
      } = {
        'X-API-KEY': userData.token,
      };

      promiseCollection.push(
        axios({
          ...apiDetails,
          headers: {
            ...headers,
            'Content-Type': apiDetails.contentType || 'application/json',
          },
          cancelToken: this.axiosCancelSource.token,
        }),
        axios({
          ...apiDetails2,
          headers: {
            ...headers,
            'Content-Type': apiDetails2.contentType || 'application/json',
          },
          cancelToken: this.axiosCancelSource.token,
        })
      );
    });

    Promise.allSettled(promiseCollection).then(
      (responses: AxiosHttpAllSettledResponsesType) => {
        if (responses.length > 0) {
          for (const item of responses) {
            if (item.status === 'fulfilled') {
              const config = item.value.config;
              if (config) {
                const urlSplit = config.url?.split?.('/');
                if (urlSplit && hasArrayValue(urlSplit)) {
                  const lastValue = urlSplit[urlSplit.length - 1];
                  if (lastValue === 'tsd.json') {
                    tempTimeSeriesCollection.push({
                      locationID: item.value.data?.data?.locationID,
                      timeSeriesData: item.value.data?.data?.timeSeriesData,
                    });
                  } else {
                    tempDetailsCollection.push({ ...item.value.data?.data });
                  }
                }
              }
            } else {
              httpCallErrorHandling(item);
            }
          }

          if (hasArrayValue(tempDetailsCollection)) {
            let tempChartData: DefaultObjectType = {};
            tempDetailsCollection.forEach((el) => {
              const matched = tempTimeSeriesCollection.find(
                (elem) => elem.locationID === el.locationID
              );
              if (hasArrayValue(el.sensorSpecs) && matched?.timeSeriesData) {
                const graphResult = manageChartData({
                  phenomList: el.sensorSpecs,
                  locationID: el.locationID,
                  locationAveragesList: matched.timeSeriesData,
                });

                tempChartData = update(tempChartData, {
                  [el.locationID]: { $set: graphResult ?? [] },
                });
              }
            });

            const tempSensorChartData = this.getSensorChartData(tempChartData);

            this.handleState({
              sensorChartData: tempSensorChartData,
              chartType: tempChartType,
              loadingRefresh: false,
            });
          }
        }
      }
    );
  };

  handleTableCheckbox = (event: CheckboxChangeEvent) => {
    const name = event?.target?.name;
    const checked = event?.target?.checked;
    const { userData } = this.props;
    const { chartData } = this.state;

    let tempChartData = { ...chartData };

    if (name && checked) {
      this.handleState({ isLoading: true });
      const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
        let locationDetails: Partial<LocationListType> = {};
        let timeSeriesData: any;
        const stateData: Partial<StateType> = { isLoading: false };
        if (responses.length > 0) {
          if (responses[0].status === 'fulfilled') {
            locationDetails = responses[0].value.data?.data;
          } else {
            httpCallErrorHandling(responses[0]);
          }

          if (responses[1].status === 'fulfilled') {
            timeSeriesData = responses[1].value.data?.data?.timeSeriesData;
          } else {
            httpCallErrorHandling(responses[1]);
          }

          if (hasObjectKey(locationDetails) && hasArrayValue(timeSeriesData)) {
            if (locationDetails.sensorSpecs) {
              const graphResult = manageChartData({
                phenomList: locationDetails.sensorSpecs,
                locationID: name,
                locationAveragesList: timeSeriesData,
              });

              tempChartData = update(tempChartData, {
                [name]: { $set: graphResult || [] },
              });

              const tempSensorChartData =
                this.getSensorChartData(tempChartData);

              stateData.chartData = tempChartData;
              stateData.sensorChartData = tempSensorChartData;
              stateData.isCheckboxAll = hasObjectKey(tempChartData);
            }
          } else {
            handleNotification('warning', {
              message: 'No data available for the selected location!',
            });
          }
        }

        this.handleState({ ...stateData });
      };

      const formData = this.getChartParams();

      const apiDetails = locationApi.getLocationDetails(undefined, {
        locationID: name,
      });

      const apiDetails2 = locationApi.getLocationAverages(
        { ...formData },
        { locationID: name }
      );

      httpCallAllSettled({
        requestConfig: [{ ...apiDetails }, { ...apiDetails2 }],
        headersConfig: {
          token: userData.token,
          cancelToken: this.axiosCancelSource.token,
        },
        applyData: handleResponses,
      });
    } else if (name && !checked) {
      const { [name]: _, ...restChartData } = tempChartData;
      const tempSensorChartData = this.getSensorChartData(restChartData);
      const payload: DefaultObjectType = {};
      if (!hasObjectKey(tempChartData)) {
        payload.chartData = {};
        payload.sensorChartData = {};
      }
      this.handleState({
        chartData: restChartData,
        sensorChartData: tempSensorChartData,
        isCheckboxAll: hasObjectKey(restChartData),
        // ...payload,
      });
    }
  };

  handleZoomReset = (idx: number) => {
    if (this.chartRefs.length > 0) {
      const matched = this.chartRefs.find((_, index) => idx === index);
      const chartInstance = matched?.chartInstance;

      (chartInstance as any)?.resetZoom?.();
    }
  };

  getSensorChartData = (chartData: DefaultObjectType) => {
    const tempSensorChartData: DefaultObjectType = {};
    if (hasObjectKey(chartData)) {
      Object.keys(chartData).forEach((el) => {
        if (hasArrayValue(chartData[el])) {
          chartData[el].forEach((el2: any) => {
            if (!tempSensorChartData[el2?.shortName]) {
              tempSensorChartData[el2.shortName] = [
                { ...el2, label: el2.locationID },
              ];
            } else {
              tempSensorChartData[el2.shortName].push({
                ...el2,
                label: el2.locationID,
              });
            }
          });
        }
      });
    }
    return tempSensorChartData;
  };

  onChangeInputFieldElements = (name: string, value: any) => {
    const { formElements } = this.state;
    const tempFormElemets = update(formElements, {
      [name]: { $set: value },
    });
    this.handleState({ formElements: tempFormElemets });
  };

  render() {
    const {
      showTable,
      partnerDataList,
      isLoading,
      searchData,
      isCheckboxAll,
      searchFilterCheckBoxData,
      locationList,
      chartData,
      chartType,
      sensorChartData,
      formElements,
      loadingRefresh,
    } = this.state;

    const { screens } = this.props;

    return (
      <Fragment>
        <Row className="p-4" justify="center">
          <Col xs={24}>
            {!showTable && (
              <FaTable
                size="1.7em"
                className={cssStyles.tableIcon}
                onClick={this.handleTableVisibility}
              />
            )}

            <Row className="pb-3">
              <Col xs={24}>
                {showTable && (
                  <AntdCard elevate>
                    <FaTimes
                      size="1.7em"
                      className={cssStyles.crossIcon}
                      onClick={this.handleTableVisibility}
                    />
                    <Row>
                      <Col xs={24}>
                        <FilterInput
                          dataList={partnerDataList}
                          handleCheckboxFilter={this.handleSearchCheckboxFilter}
                          handleSearchFilter={this.handleSearchFilter}
                          loading={isLoading}
                          searchData={searchData}
                          filterCheckBoxData={searchFilterCheckBoxData}
                        />
                      </Col>
                    </Row>

                    <Row justify="center" className="py-3">
                      <Col>
                        <Checkbox
                          checked={isCheckboxAll}
                          disabled={!isCheckboxAll}
                          name="ALL"
                          onChange={
                            this.handleCheckboxAll
                          }>{`Deselect All`}</Checkbox>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24}>
                        <Table
                          bordered
                          scroll={{ x: true }}
                          dataSource={locationList}
                          loading={{ size: 'large', spinning: isLoading }}
                          columns={getTableColumns({
                            checkbox: chartData,
                            handleTableCheckbox: this.handleTableCheckbox,
                          })}
                          pagination={{
                            pageSize: 10,
                            showSizeChanger: false,
                            total: locationList.length,
                            size: screens.xs ? undefined : 'default',
                            simple: screens.xs ? true : false,
                          }}
                          rowKey={'uuid'}
                        />
                      </Col>
                    </Row>
                  </AntdCard>
                )}
              </Col>
            </Row>

            {/* --- Chart Input Fields --- */}
            <ChartInputFields
              formElements={formElements}
              onInputChange={this.onChangeInputFieldElements}
              onButtonClick={this.onRefreshClick}
              loading={isLoading || loadingRefresh}
              showAutoUpdate={false}
              isDisabled={!hasObjectKey(sensorChartData)}
              showSplitTime
            />

            {/* Chart Data */}
            {sensorChartData &&
              hasObjectKey(sensorChartData) &&
              Object.keys(sensorChartData).map((el, idx) => {
                return (
                  <Fragment key={el}>
                    <Row className="pt-3">
                      <Col xs={24}>
                        <AntdCard elevate>
                          <Row justify="center">
                            <Col>
                              <Button
                                type="primary"
                                htmlType="button"
                                onClick={() => this.handleZoomReset(idx)}>
                                Reset Zoom
                              </Button>
                            </Col>
                          </Row>

                          <Row justify="center" className="py-2">
                            <Col>{el}</Col>
                          </Row>

                          <Row justify="center">
                            <Col xs={24}>
                              {sensorChartData[el] &&
                                sensorChartData[el].length > 0 && (
                                  <LineChart
                                    ref={(ref) => {
                                      this.chartRefs[idx] = ref;
                                    }}
                                    // redraw={true}
                                    data={{
                                      datasets: [...sensorChartData[el]],
                                    }}
                                    options={{
                                      scales: {
                                        xAxes: [
                                          {
                                            type: 'time',
                                            time: {
                                              unit: chartType,
                                            },
                                          },
                                        ],
                                      },
                                      responsive: true,
                                      plugins: {
                                        zoom: {
                                          pan: {
                                            enabled: false,
                                            mode: 'x',
                                          },
                                          zoom: {
                                            enabled: true,
                                            mode: 'x',
                                            // onZoom: onChartZoom,
                                          },
                                        },
                                      },
                                    }}
                                  />
                                )}
                            </Col>
                          </Row>
                        </AntdCard>
                      </Col>
                    </Row>
                  </Fragment>
                );
              })}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withBreakpoint(MultipleLocationsCharts);

const getTableColumns = (params?: {
  checkbox: BooleanObjectType;
  handleTableCheckbox: (e: CheckboxChangeEvent) => void;
}): AntdTableColumnsType<LocationListType>[] => {
  return [
    {
      title: 'Location Name',
      key: 'name',
      dataIndex: 'name',
      sorter: (a: LocationListType, b: LocationListType) =>
        handleSorting(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Location ID',
      key: 'locationID',
      dataIndex: 'locationID',
      sorter: (a: LocationListType, b: LocationListType) =>
        handleSorting(a.locationID, b.locationID),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Last Connected',
      key: 'lastContact',
      dataIndex: 'lastContact',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: LocationListType, index: number) => {
        return (
          <Fragment>
            <Row>
              <Col xs={4}>
                <Checkbox
                  name={record.locationID}
                  onChange={params?.handleTableCheckbox}
                  checked={params?.checkbox?.[record.locationID]}></Checkbox>
              </Col>
            </Row>
          </Fragment>
        );
      },
    },
  ];
};
