import { DefaultObjectType } from '../../@types';

/**
 * Get Highest Value from Array Of Objects based on specific "key"
 * @param {Object} data Array of Objects
 * @param {String} key This is used to select a specific property from the Array
 * @returns {Object | null} Returns the Object from the Array which has the highest value for the specific "key"
 */
export function getHighestValArrObj(
  data: DefaultObjectType[],
  key: string
): DefaultObjectType | null {
  const maxValue = Math.max.apply(
    Math,
    data.map((el) => {
      return el[key];
    })
  );

  const maxValueObject = data.find((el) => {
    return el[key] === maxValue;
  });

  return maxValueObject ?? null;
}

/**
 * Get Lowest Value from Array Of Objects based on specific "key"
 * @param {Object} data Array of Objects
 * @param {String} key This is used to select a specific property from the Array
 * @returns {Object | null} Returns the Object from the Array which has the lowest value for the specific "key"
 */
export function getLowestValArrObj(
  data: DefaultObjectType[],
  key: string
): DefaultObjectType | null {
  const minValue = Math.min.apply(
    Math,
    data.map((el) => {
      return el[key];
    })
  );

  const minValueObject = data.find((el) => {
    return el[key] === minValue;
  });

  return minValueObject ?? null;
}

/**
 * Transform all Keys of an Object to Upper Case.
 * @param {Object} data Object to transform
 * @returns {Object} Object keys transformed to Upper Case
 */
export const getObjectUpperCase = (
  data: DefaultObjectType
): DefaultObjectType => {
  return Object.fromEntries(
    Object.entries(data).map(([k, v]) => [k.toUpperCase(), v])
  );
};

/**
 * Transform all Keys of an Object to Lower Case.
 * @param {Object} data Object to transform
 * @returns {Object} Object keys transformed to Lower Case
 */
export const getObjectLowerCase = (
  data: DefaultObjectType
): DefaultObjectType => {
  return Object.fromEntries(
    Object.entries(data).map(([k, v]) => [k.toLowerCase(), v])
  );
};

/**
 * Checks if an array length is zero or greater than zero. Returns true | false
 * @param {Array} data Array to check
 * @returns {Boolean} true | false
 */
export const hasArrayValue = (data: any[]): boolean => {
  if (data) {
    return data.length > 0;
  }
  return false;
};

/**
 * Checks if an object has at least one "key". Returns true | false
 * @param {Object} data Object to check
 * @returns {Boolean} true | false
 */
export const hasObjectKey = (data: DefaultObjectType): boolean => {
  if (data) {
    return Object.keys(data).length > 0;
  }
  return false;
};

/**
 * Transforms a floating point value to two decimal points
 * @param {Number | String} value Value to be transformed
 * @returns {Number} Floating point number with two points
 */
export const getTwoDecimalPoints = (value: number | string): number => {
  let isFloat = Number(value) % 1 !== 0;
  if (isFloat) {
    const valueLength = value.toString().split('.')[1].length;

    if (valueLength > 2) {
      value = parseFloat(value.toString()).toFixed(2);
    }
  }

  return Number(value);
};
