export function getApiPath() {
  if (process.env.NODE_ENV === 'development') {
    return `https://maint.airsensa.tech`;
  }
  if (process.env.NODE_ENV === 'production') {
    return `https://maint.airsensa.tech`;
  }
}

export function getOldApiPath(params?: string) {
  if (process.env.NODE_ENV === 'development') {
    // return `https://${params}dev.airsensa.io`;
    return `https://apidev.airsensa.io`;
  }
  if (process.env.NODE_ENV === 'production') {
    return `https://apidev.airsensa.io`;
  }
}
