import { Fragment, useLayoutEffect } from 'react';
import { Button, Card, Col, Row } from 'antd';

import cssStyles from '../styles/floorPlans.module.scss';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { FloorPlanLocationsWithPosition } from '../helpers';
import { useHistory } from 'react-router';
import { locationRoutes } from '../../../routes/routes-list';
import GaugeSection from '../GaugeSection';
import MapSection from '../MapSection';
import { FloorPlanMapDetailsType } from '@airsensa/react-components/dist/@types';

type PropsType = {
  floorPlanLocationList: FloorPlanLocationsWithPosition[];
  mapDetails: FloorPlanMapDetailsType;
  onLocationSelect: (value: string) => void;
  selectedLocationID: string;
  loading: boolean;
  selectedPhenoms: string[];
};

const LocationListSection = ({
  floorPlanLocationList,
  mapDetails,
  onLocationSelect,
  selectedLocationID,
  loading,
  selectedPhenoms,
}: PropsType) => {
  const screens = useBreakpoint();
  const history = useHistory();

  useLayoutEffect(() => {
    if (selectedLocationID) {
      const element = document.getElementById(selectedLocationID);
      element?.scrollIntoView();
    }
  }, [selectedLocationID]);

  const handleNavigateKioskView = () => {
    if (selectedLocationID) {
      const selected = floorPlanLocationList.find(
        (el) => el.locationID === selectedLocationID
      );
      if (selected) {
        history.push({
          pathname: locationRoutes.kiosk({
            locationID: selected.locationID,
          }),
          state: {
            mapDetails: {
              ...mapDetails,
              currentCoordinates: {
                x: selected.x,
                y: selected.y,
              },
            },
          },
        });
      }
    }
  };

  const handleNavigateCarouselView = () => {
    if (selectedLocationID) {
      const selected = floorPlanLocationList.find(
        (el) => el.locationID === selectedLocationID
      );
      if (selected) {
        history.push({
          pathname: locationRoutes.carousel({
            locationID: selected.locationID,
          }),
          // state: {
          //   redirectLink: locationRoutes.floorPlan(),
          // },
        });
      }
    }
  };

  const handleNavigateHomeView = () => {
    if (selectedLocationID) {
      const selected = floorPlanLocationList.find(
        (el) => el.locationID === selectedLocationID
      );
      if (selected) {
        history.push({
          pathname: locationRoutes.dashboard({
            locationID: selected.locationID,
          }),
          state: {
            mapDetails: {
              ...mapDetails,
              currentCoordinates: {
                x: selected.x,
                y: selected.y,
              },
            },
          },
        });
      }
    }
  };

  let isButtonDisabled = true;
  if (selectedLocationID) {
    isButtonDisabled = false;
  }

  return (
    <Fragment>
      <Row className="py-3">
        <Col xs={24} md={8} style={{ height: '400px' }}>
          <Card
            loading={loading}
            className="h-100"
            bodyStyle={{ height: '100%', padding: 0 }}>
            <Row
              justify="center"
              align="middle"
              // style={{
              //   height: screens.xs ? '50%' : undefined,
              // }}
              className="p-3">
              <Col
                style={{
                  // transform: screens.xs ? `rotate(90deg)` : undefined,
                  fontWeight: 'bold',
                }}>
                Dashboard
              </Col>
            </Row>

            <Fragment>
              <Row
                justify="center"
                align="middle"
                className={cssStyles.boxRoot}>
                <LocationBox
                  floorPlanLocations={floorPlanLocationList}
                  onLocationSelect={onLocationSelect}
                  selectedLocationID={selectedLocationID}
                />
              </Row>
              <Row
                justify="center"
                align="middle"
                className={`px-3 mt-2 ${cssStyles.navigation}`}>
                <Col xs={24} sm={16} xl={8}>
                  <Button
                    type="primary"
                    disabled={isButtonDisabled}
                    onClick={handleNavigateKioskView}
                    block>
                    Kiosk View
                  </Button>
                </Col>
                <Col
                  xs={24}
                  sm={16}
                  xl={8}
                  className="py-2 py-xl-0 px-xl-2 mx-2 mx-xl-0">
                  <Button
                    type="primary"
                    disabled={isButtonDisabled}
                    onClick={handleNavigateCarouselView}
                    block>
                    Carousel View
                  </Button>
                </Col>
                <Col xs={24} sm={16} xl={8}>
                  <Button
                    type="primary"
                    disabled={isButtonDisabled}
                    onClick={handleNavigateHomeView}
                    block>
                    Device Homepage
                  </Button>
                </Col>
              </Row>
            </Fragment>
          </Card>
          {screens.md && (
            <GaugeSection
              floorPlanLocationList={floorPlanLocationList}
              selectedLocationID={selectedLocationID}
              loading={loading}
              selectedPhenoms={selectedPhenoms}
            />
          )}
        </Col>

        <MapSection
          loading={loading}
          floorPlanLocationList={floorPlanLocationList}
          mapDetails={mapDetails}
          onLocationSelectFromMap={onLocationSelect}
          selectedLocationID={selectedLocationID}
          selectedPhenoms={selectedPhenoms}
        />
      </Row>
      {screens.sm && !screens.md && (
        <GaugeSection
          floorPlanLocationList={floorPlanLocationList}
          selectedLocationID={selectedLocationID}
          loading={loading}
          selectedPhenoms={selectedPhenoms}
        />
      )}
    </Fragment>
  );
};

export default LocationListSection;

const LocationBox = ({
  floorPlanLocations,
  onLocationSelect,
  selectedLocationID,
}: {
  floorPlanLocations: FloorPlanLocationsWithPosition[];
  onLocationSelect: (value: string) => void;
  selectedLocationID: string;
}) => {
  const screens = useBreakpoint();

  let width = 90;
  if (screens.xl) {
    width = 40;
  }

  return (
    <Fragment>
      {floorPlanLocations.length > 0 ? (
        floorPlanLocations.map((el) => {
          return (
            <Fragment key={el.uuid}>
              <Col
                data-locationid={el.locationID}
                id={el.locationID}
                xs={24}
                xl={12}
                style={{
                  maxWidth: `${width}%`,
                  flex: `0 0 ${width}%`,
                }}
                className={`p-2 m-2 ${cssStyles.box} 
                ${
                  el.locationID === selectedLocationID
                    ? cssStyles.boxSelected
                    : ''
                }`}
                onClick={() => onLocationSelect(el.locationID)}>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    width: `100%`,
                    height: '1.2rem',
                    borderRadius: '0.85rem',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    backgroundColor: el.colorCode,
                  }}></div>
                <div className="location-id">{el.locationName}</div>
                <div className="location-name">{el.locationDescription}</div>
              </Col>
            </Fragment>
          );
        })
      ) : (
        <Col>
          <span
            style={{
              fontSize: '16px',
              fontStyle: 'italic',
              color: 'rgb(54, 114, 248)',
            }}>
            No floorplans to display
          </span>
        </Col>
      )}
    </Fragment>
  );
};
