import { Col, Row } from 'antd';
import { Dispatch, Fragment, useReducer, useEffect, memo } from 'react';
import { RouteComponentProps } from 'react-router';
import { apiCall } from '../../api-services/api';
import { locationApi } from '../../api-services/api-list';
import ReactSpeedoMeter from '../../components-shared/ReactSpeedoMeter';
import { GaugeListType, ReducerHookActionType } from '../../@types';
import { SensorListType } from '../../@types';
import { manageGaugeData } from '../../utils';
import {
  StateType,
  reducer,
  initState,
} from '../components/WebViewGaugeChart/helpers';
import { ReducerActionTypes } from '../../shared/helpers';

type PropsType = RouteComponentProps<{
  locationID: string;
  token: string;
  theme: 'dark' | 'light';
}>;

const WebViewGaugeChart = ({ match }: PropsType) => {
  const [state, dispatchToState]: [
    state: StateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(reducer, initState);

  const locationID = match.params.locationID;
  const token = match.params.token;
  const theme = match.params.theme;

  const { gaugeList } = state;

  useEffect(() => {
    const fetchData = async () => {
      if (token && locationID && gaugeList.length === 0) {
        const getLocationLatest = locationApi.getLocationLatest(undefined, {
          locationID,
        });

        const getLocationDetails = locationApi.getLocationDetails(undefined, {
          locationID,
        });

        // let details: LocationListType;
        let sensors: SensorListType[] = [];
        let gaugeData: GaugeListType[] = [];

        try {
          const responses = await Promise.all([
            apiCall({
              storeToken: token,
              url: getLocationDetails.url,
              method: getLocationDetails.method,
              contentType: getLocationDetails.contentType,
            }),
            apiCall({
              storeToken: token,
              url: getLocationLatest.url,
              method: getLocationLatest.method,
              contentType: getLocationLatest.contentType,
            }),
          ]);

          if (responses.length > 0) {
            if (responses?.[0]?.data?.data) {
              // details = responses[0].data.data;
              sensors = responses[0].data.data?.sensors;
            }

            if (responses?.[1]?.data?.data) {
              const data = responses[1].data.data;

              const gaugeSensors = data?.latestData?.Dnum;

              if (sensors && sensors.length > 0 && gaugeSensors) {
                let tempGaugeData = manageGaugeData({
                  phenomList: sensors,
                  locationID,
                  gaugeSensors,
                });
                if (tempGaugeData) {
                  gaugeData = [...tempGaugeData];
                }
              }
            }
          }

          dispatchToState({
            type: ReducerActionTypes.SetState,
            payload: { gaugeList: gaugeData, loading: false },
          });
        } catch (error) {}
      }
    };
    fetchData();
  }, [gaugeList.length, locationID, token]);

  return (
    <Fragment>
      <Row
        className="pt-5"
        justify="center"
        style={{ height: '100vh', backgroundColor: '#fff' }}>
        <Col xs={24} style={{ backgroundColor: '#fff' }}>
          {gaugeList.length > 0 &&
            gaugeList.map((el) => {
              return (
                <Fragment key={el.shortName}>
                  <Row
                    justify="center"
                    style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    <Col className="text-center">{el.longName}</Col>
                  </Row>
                  <Row justify="center">
                    <Col xs={20}>
                      <ReactSpeedoMeter
                        {...el}
                        speedoMeterProps={{
                          needleColor: theme === 'dark' ? '#FFFFFF' : '#000000',
                          ringWidth: 30,
                          textColor: theme === 'dark' ? '#FFFFFF' : '#000000',
                          width: 250,
                          height: 200,
                          valueTextFontSize: '16px',
                        }}
                      />
                    </Col>
                  </Row>
                </Fragment>
              );
            })}
        </Col>
      </Row>
    </Fragment>
  );
};

export default memo(WebViewGaugeChart);
