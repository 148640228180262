import {
  Dispatch,
  FormEvent,
  Fragment,
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import { RouteComponentProps } from 'react-router';
import update from 'immutability-helper';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Button, Card, Col, message, Row } from 'antd';

import useHttp from '../hooks/use-http';
import { UserDataType } from '../@types';
import { ApiResponseDataType, ReducerHookActionType } from '../@types';
import {
  initState,
  reducer,
  StateType,
  FormElementsType,
} from '../components/ProfileDetails/helpers';
import {
  getMultipleInputElements,
  getSingleInputElement,
} from '../utils/get-input-element';
import { checkValidation } from '../utils/validation';
import MapModal from '../components/ProfileDetails/MapModal';
import ChangePasswordModal from '../components-shared/ChangePasswordModal';
import { handleNotification } from '../utils/notification-handler';
import ApiKeysModal from '../components/ProfileDetails/ApiKeysModal';
import { userApi } from '../api-services/api-list';
import { AxiosResponse } from 'axios';
import { ReducerActionTypes } from '../shared/helpers';

type PropsType = RouteComponentProps & {
  userData: Partial<UserDataType>;
};

const ProfileDetails = ({ userData }: PropsType) => {
  const [state, dispatchToState]: [
    state: StateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(reducer, initState);
  const { isLoading, sendRequest } = useHttp();
  const screens = useBreakpoint();

  const {
    formElements,
    initLat,
    initLng,
    showMapModal,
    showApiKeysModal,
    showChangePass,
    mapZoom,
  } = state;

  const isButtonDisabled = !formElements.userName.value;

  useEffect(() => {
    if (userData.username && formElements.userName.value === '') {
      const tempFormElements = update(formElements, {
        userName: { value: { $set: userData.username || '' } },
        userEmail: { value: { $set: userData.email || '' } },
        userPhone: { value: { $set: userData.phone || '' } },
        latitude: {
          value: {
            $set:
              userData.lat && userData.lat !== 0
                ? userData.lat?.toString()
                : userData.partnerLat?.toString() || '',
          },
        },
        longitude: {
          value: {
            $set:
              userData.lng && userData.lng !== 0
                ? userData.lng?.toString()
                : userData.partnerLng?.toString() || '',
          },
        },
      });

      dispatchToState({
        type: ReducerActionTypes.SetState,
        payload: {
          formElements: { ...tempFormElements },
          initLat:
            userData.lat && userData.lat !== 0
              ? userData.lat?.toString()
              : userData.partnerLat?.toString() || '',
          initLng:
            userData.lng && userData.lng !== 0
              ? userData.lng?.toString()
              : userData.partnerLng?.toString() || '',
        },
      });
    }
    return () => {};
  }, [formElements, userData]);

  const inputChangedHandler = useCallback(
    (name: keyof FormElementsType, value: any) => {
      if (name) {
        if (
          value &&
          (name === 'latitude' ||
            name === 'longitude' ||
            name === 'userPhone') &&
          !checkValidation(value, formElements[name].validation)
        ) {
          message.error('Please enter valid Coordinates');
          return;
        }

        const tempFormElements = update(formElements, {
          [name]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(value, formElements[name].validation),
            },
            value: { $set: value },
          },
        });

        dispatchToState({
          type: ReducerActionTypes.SetState,
          payload: {
            formElements: { ...tempFormElements },
          },
        });
      }
    },
    [formElements]
  );

  const onMapMarkerMove = useCallback(
    (lat: number, lng: number) => {
      const tempFormElements = update(formElements, {
        latitude: { value: { $set: lat.toString() } },
        longitude: { value: { $set: lng.toString() } },
      });
      dispatchToState({
        type: ReducerActionTypes.SetState,
        payload: { formElements: { ...tempFormElements } },
      });
    },
    [formElements]
  );

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault?.();

    if (userData.userID && userData.token) {
      const { url, method, contentType } = userApi.putUserDetails(undefined, {
        userID: userData.userID,
      });

      const handleSubmit = (response: AxiosResponse<ApiResponseDataType>) => {
        const result = response.data;

        if (result) {
          handleNotification('success', result.data);
        }
      };

      const data = {
        username: formElements.userName.value,
        email: formElements.userEmail.value,
        phone: formElements.userPhone.value,
        lat: Number(formElements.latitude.value),
        lng: Number(formElements.longitude.value),
      };

      sendRequest(
        { url, method, contentType, storeToken: userData.token, data },
        handleSubmit
      );
    }
  };

  const onMapModalOpen = () => {
    dispatchToState({
      type: ReducerActionTypes.SetState,
      payload: { showMapModal: true },
    });
  };

  const onMapModalClose = () => {
    const tempFormElements = update(formElements, {
      latitude: { value: { $set: initLat } },
      longitude: { value: { $set: initLng } },
    });
    dispatchToState({
      type: ReducerActionTypes.SetState,
      payload: { showMapModal: false, formElements: { ...tempFormElements } },
    });
  };

  const onMapModalOkay = () => {
    dispatchToState({
      type: ReducerActionTypes.SetState,
      payload: {
        showMapModal: false,
        initLat: formElements.latitude.value,
        initLng: formElements.longitude.value,
      },
    });
  };

  const handleChangePassModal = (params?: boolean) => {
    dispatchToState({ type: ReducerActionTypes.SetChangePass });
    if (params) {
      handleNotification('success', {
        message: 'Password Changed Successfully!',
      });
    }
  };

  const handleApiKeysModal = () => {
    dispatchToState({ type: ReducerActionTypes.SetApiKeys });
  };

  const single = getSingleInputElement({
    formElements,
    inputChangedHandler,
    sliceValue: [0, 3],
  });

  const multiple = getMultipleInputElements({
    formElements,
    inputChangedHandler,
    sliceValue: [3, 5],
    optionalElement: (
      <Col lg={4} className="pl-lg-2" style={{ paddingTop: '0.8rem' }}>
        <Button
          type="primary"
          htmlType="button"
          onClick={onMapModalOpen}
          disabled={isButtonDisabled}>
          MAP
        </Button>
      </Col>
    ),
  });

  return (
    <Fragment>
      <Row className="p-3" style={{ minHeight: '100vh' }}>
        <Col xs={24}>
          <Card style={{ minHeight: '100vh' }}>
            <form noValidate onSubmit={onFormSubmit}>
              <Row>
                <Col xs={24} sm={12}>
                  {single}
                  {multiple}

                  <Row className="py-3">
                    <Col xs={24} sm={10}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isButtonDisabled}
                        loading={isLoading}>
                        Submit
                      </Button>
                    </Col>
                    <Col
                      xs={24}
                      sm={10}
                      className="pt-3 pt-sm-0"
                      style={{ paddingLeft: screens.xs ? '' : '11px' }}>
                      <Button
                        type="primary"
                        htmlType="button"
                        disabled={isButtonDisabled}
                        onClick={handleChangePassModal.bind(null, undefined)}>
                        Change Password
                      </Button>
                    </Col>
                    <Col xs={24} sm={10} className="pt-3">
                      <Button
                        type="primary"
                        htmlType="button"
                        disabled={isButtonDisabled}
                        onClick={handleApiKeysModal}>
                        API Keys
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>
          </Card>
        </Col>
      </Row>

      {showMapModal && (
        <MapModal
          showModal={showMapModal}
          mapZoom={mapZoom}
          lat={Number(formElements.latitude.value) || 0}
          lng={Number(formElements.longitude.value) || 0}
          onMapModalClose={onMapModalClose}
          onMapModalOkay={onMapModalOkay}
          onMapMarkerMove={onMapMarkerMove}
        />
      )}

      {showChangePass && (
        <ChangePasswordModal
          showModal={showChangePass}
          userName={userData.username || ''}
          handleModal={handleChangePassModal}
        />
      )}

      {showApiKeysModal && (
        <ApiKeysModal
          showModal={showApiKeysModal}
          handleModal={handleApiKeysModal}
          storeToken={userData.token}
          userID={userData.userID}
        />
      )}
    </Fragment>
  );
};

export default ProfileDetails;
