import { Col, Row } from 'antd';
import { Fragment } from 'react';
import { FloorPlanMap } from '@airsensa/react-components';
import LeafletMap from '../../../components-shared/LeafletMap';
import { FloorPlanMapDetailsType } from '@airsensa/react-components/dist/@types';

type PropsType = {
  mapDetails: FloorPlanMapDetailsType;
  lat: number;
  lng: number;
};

const MapSection = ({ mapDetails, lat, lng }: PropsType) => {
  return (
    <Fragment>
      <Row className="map-section-gap" />

      <Row justify="center">
        <Col xs={20}>
          {mapDetails ? (
            <Fragment>
              <FloorPlanMap
                mapDetails={mapDetails}
                mapContainerProps={{ className: 'leaflet-map map' }}
                coordinates={[
                  mapDetails.currentCoordinates?.y ?? 0,
                  mapDetails.currentCoordinates?.x ?? 0,
                ]}
              />
            </Fragment>
          ) : (
            <Fragment>
              <LeafletMap
                zoom={12}
                lat={lat}
                lng={lng}
                mapContainerProps={{ className: 'leaflet-map map' }}
              />
            </Fragment>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

export default MapSection;
