import { Fragment, Component } from 'react';
import { Row, Col, Button, Input, Select, Popover } from 'antd';
import { ColorResult, SketchPicker } from 'react-color';
import {
  CustomDivConfigDetails,
  displayTypes,
  sensorTypes,
  timeList,
} from '../helpers';

type PropsType = CustomDivConfigDetails & {
  hours: string;
  isColorVisible: boolean;
  isConfigVisible: boolean;
  locations: string[];
  id: string;
  handleInputChange: (value: string, name: string, id: string) => void;
  onDisableMove: (id: string, visible: boolean) => void;
  onSubmitClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
};

type StateType = {
  background: string;
  show: boolean;
};

class PopupContent extends Component<PropsType, StateType> {
  state = {
    background: '#fff',
    show: false,
  };

  handleChangeComplete = (color: ColorResult) => {
    this.setState({ background: color.hex }, () => {
      const { handleInputChange, id } = this.props;
      handleInputChange(color.hex, 'color', id);
    });
  };

  handleVisibility = (visible: boolean) => {
    const { onDisableMove, id } = this.props;
    onDisableMove(id, visible);
  };

  render() {
    const {
      locations,
      locationId,
      handleInputChange,
      id,
      displayType,
      sensor,
      color,
      hours,
      refreshInterval,
      freeText,
      isColorVisible,
      textSize,
      onSubmitClick,
      onDeleteClick,
    } = this.props;
    const { background } = this.state;

    return (
      <Fragment>
        <Row justify="space-around">
          <Col xs={10}>Display Type:</Col>
          <Col xs={14}>
            <Select
              id="displayType"
              value={displayType}
              style={{ width: '100%' }}
              placeholder="Select Display Type"
              onChange={(value) => handleInputChange(value, 'displayType', id)}>
              {displayTypes.map((el) => {
                return (
                  <Fragment key={el.value}>
                    <Select.Option value={el.value}>{el.text}</Select.Option>
                  </Fragment>
                );
              })}
            </Select>
          </Col>
        </Row>

        {(displayType === 'gauge' || displayType === 'chart') && (
          <Fragment>
            <Row style={{ paddingTop: 10 }}>
              <Col xs={10}>Location Id:</Col>
              <Col xs={14}>
                <Select
                  id="locationId"
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Search Location Id"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option as any)?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA as any).children
                      .toLowerCase()
                      .localeCompare((optionB as any).children.toLowerCase())
                  }
                  value={locationId}
                  onChange={(value) =>
                    handleInputChange(value, 'locationId', id)
                  }>
                  {locations?.map((el) => {
                    return (
                      <Fragment key={el}>
                        <Select.Option value={el}>{el}</Select.Option>
                      </Fragment>
                    );
                  })}
                </Select>
              </Col>
            </Row>

            <Row justify="space-around" style={{ paddingTop: 10 }}>
              <Col xs={10}>Sensor:</Col>
              <Col xs={14}>
                <Select
                  id="sensor"
                  value={sensor}
                  style={{ width: '100%' }}
                  placeholder="Select Sensor"
                  onChange={(value) => handleInputChange(value, 'sensor', id)}>
                  {sensorTypes.map((el) => {
                    return (
                      <Fragment key={el.value}>
                        <Select.Option value={el.value}>
                          {el.text}
                        </Select.Option>
                      </Fragment>
                    );
                  })}
                </Select>
              </Col>
            </Row>

            {displayType !== 'gauge' && (
              <Fragment>
                <ColorField
                  isColorVisible={isColorVisible}
                  handleVisibility={this.handleVisibility}
                  background={background}
                  handleChangeComplete={this.handleChangeComplete}
                  color={color}
                />

                <Row justify="space-around" style={{ paddingTop: 10 }}>
                  <Col xs={10}>Hours:</Col>
                  <Col xs={14}>
                    <Select
                      id="hours"
                      value={hours}
                      style={{ width: '100%' }}
                      placeholder="Select Sensor"
                      onChange={(value) =>
                        handleInputChange(value, 'hours', id)
                      }>
                      {timeList.map((el) => {
                        return (
                          <Fragment key={el.value}>
                            <Select.Option value={el.value}>
                              {el.text}
                            </Select.Option>
                          </Fragment>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
              </Fragment>
            )}

            <Row justify="space-around" style={{ paddingTop: 10 }}>
              <Col xs={10}>Refresh Interval(mins):</Col>
              <Col xs={14}>
                <Input
                  id="refreshInterval"
                  name="refreshInterval"
                  value={refreshInterval}
                  onChange={(e) =>
                    handleInputChange(
                      e.currentTarget.value,
                      'refreshInterval',
                      id
                    )
                  }
                />
              </Col>
            </Row>
          </Fragment>
        )}

        {displayType === 'freeText' && (
          <Fragment>
            <Row justify="space-around" style={{ paddingTop: 10 }}>
              <Col xs={10}>Text:</Col>
              <Col xs={14}>
                <Input
                  id="freeText"
                  name="freeText"
                  value={freeText}
                  onChange={(e) =>
                    handleInputChange(e.currentTarget.value, 'freeText', id)
                  }
                />
              </Col>
            </Row>

            <ColorField
              isColorVisible={isColorVisible}
              handleVisibility={this.handleVisibility}
              background={background}
              handleChangeComplete={this.handleChangeComplete}
              color={color}
            />

            <Row justify="space-around" style={{ paddingTop: 10 }}>
              <Col xs={10}>Text Size:</Col>
              <Col xs={14}>
                <Input
                  id="textSize"
                  name="textSize"
                  value={textSize}
                  onChange={(e) =>
                    handleInputChange(e.currentTarget.value, 'textSize', id)
                  }
                />
              </Col>
            </Row>
          </Fragment>
        )}

        <Row style={{ paddingTop: 10 }} justify="end">
          <Col>
            <Button
              type="primary"
              danger
              htmlType="button"
              block
              onClick={() => onDeleteClick(id)}>
              Delete
            </Button>
          </Col>
          <Col offset={1}>
            <Button
              type="primary"
              htmlType="button"
              block
              onClick={() => onSubmitClick(id)}>
              Submit
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default PopupContent;

const ColorField = ({
  isColorVisible,
  handleVisibility,
  background,
  handleChangeComplete,
  color,
}: {
  isColorVisible: boolean;
  handleVisibility: (visible: boolean) => void;
  background: string;
  handleChangeComplete: (color: ColorResult) => void;
  color: string;
}) => {
  return (
    <Fragment>
      <Row justify="space-around" style={{ paddingTop: 10 }}>
        <Col xs={10}>Color:</Col>
        <Col xs={14}>
          <Popover
            trigger="click"
            visible={isColorVisible}
            onVisibleChange={handleVisibility}
            content={
              <SketchPicker
                color={background}
                onChangeComplete={handleChangeComplete}
              />
            }>
            <Input value={color} readOnly style={{ cursor: 'pointer' }} />
          </Popover>
        </Col>
      </Row>
    </Fragment>
  );
};
