import { Fragment } from 'react';
import { Card, Col, Row } from 'antd';

import { FloorPlanLocationsWithPosition } from '../helpers';
import { FloorPlanMapDetailsType } from '@airsensa/react-components/dist/@types';
import PolygonMap from '../PolygonMap';

type PropsType = {
  floorPlanLocationList: FloorPlanLocationsWithPosition[];
  mapDetails: FloorPlanMapDetailsType;
  onLocationSelectFromMap: (value: string) => void;
  selectedLocationID: string;
  loading: boolean;
  selectedPhenoms: string[];
};

const MapSection = ({
  loading,
  mapDetails,
  floorPlanLocationList,
  selectedLocationID,
  onLocationSelectFromMap,
}: PropsType) => {
  return (
    <Fragment>
      <Col
        xs={24}
        md={16}
        className="pl-md-2 pt-3 pt-md-0"
        style={{ height: '800px' }}>
        <Card
          className="h-100"
          bodyStyle={{ height: '100%' }}
          loading={loading}>
          <Row className="h-100" align="middle" justify="center">
            <Col xs={24} className="h-100">
              {mapDetails.image && mapDetails.height && (
                <PolygonMap
                  mapDetails={mapDetails}
                  floorPlanLocationList={floorPlanLocationList}
                  selectedLocationID={selectedLocationID}
                  onMarkerClick={onLocationSelectFromMap}
                />
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Fragment>
  );
};

export default MapSection;
