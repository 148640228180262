import { Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Fragment } from 'react';

import AntdCard from '../../../components-shared/AntdCard';
import CompassWithImages from '../../../components-shared/CompassWithImages/CompassWithImages';

import LeafletMap from '../../../components-shared/LeafletMap';
import ReactSpeedoMeter from '../../../components-shared/ReactSpeedoMeter';
import { GaugeListType } from '../../../@types';
import { FloorPlanMapDetailsType } from '@airsensa/react-components/dist/@types';
import { getTwoDecimalPoints } from '../../../shared/helpers';
import FloorPlanMap from '../../../components-shared/FloorPlanMap';

function MapGauge({
  loading,
  gaugeList,
  lat,
  lng,
  floorPlanMapDetails,
}: {
  loading: boolean;
  gaugeList: GaugeListType[];
  lat: number;
  lng: number;
  floorPlanMapDetails: FloorPlanMapDetailsType;
}) {
  return (
    <Fragment>
      <Row className="py-3">
        <Col xs={24}>
          <AntdCard loading={loading} elevate>
            <Row
              style={{ minHeight: '500px' }}
              justify="space-around"
              gutter={[0, { xs: 32 }]}>
              <Gauge gaugeList={gaugeList} />
              <Map
                lat={lat}
                lng={lng}
                floorPlanMapDetails={floorPlanMapDetails}
              />
            </Row>
          </AntdCard>
        </Col>
      </Row>
    </Fragment>
  );
}

export default MapGauge;

function Gauge({ gaugeList }: { gaugeList: GaugeListType[] }) {
  const screens = useBreakpoint();
  return (
    <Col xs={24} lg={12}>
      <Row justify={screens.xs || gaugeList.length === 0 ? 'center' : 'start'}>
        {gaugeList.length > 0 ? (
          gaugeList.map((el) => {
            const actualValue = getTwoDecimalPoints(el.actualValue);
            if (!el.longName) {
              return (
                <Fragment key={el.shortName}>
                  <Col style={{ paddingBottom: 12 }}>
                    <GuageWithoutSensorSpecs record={el} />
                  </Col>
                </Fragment>
              );
            }
            if (el.compass) {
              return (
                <Fragment key={el.shortName}>
                  <Col>
                    <Row justify="center">
                      <Col>{el.longName}</Col>
                    </Row>
                    <Row justify="center" className="py-2">
                      <Col>
                        <CompassWithImages rotateValue={actualValue} />
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col>{actualValue}</Col>
                    </Row>
                  </Col>
                </Fragment>
              );
            }
            return (
              <Fragment key={el.shortName}>
                <Col>
                  <Row justify="center">
                    <Col>{el.longName}</Col>
                  </Row>
                  <Row justify="center">
                    <Col>
                      <ReactSpeedoMeter
                        {...el}
                        actualValue={actualValue}
                        speedoMeterProps={{ height: 140 }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Fragment>
            );
          })
        ) : (
          <Fragment>
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
              <span
                style={{
                  fontSize: '16px',
                  fontStyle: 'italic',
                  color: 'rgb(54, 114, 248)',
                }}>
                No recent data
              </span>
            </Col>
          </Fragment>
        )}
      </Row>
    </Col>
  );
}

function Map({
  lat,
  lng,
  floorPlanMapDetails,
}: {
  lat: number;
  lng: number;
  floorPlanMapDetails: FloorPlanMapDetailsType;
}) {
  const screens = useBreakpoint();

  return (
    <Col
      xs={24}
      lg={12}
      style={{
        minHeight: screens.xs || screens.sm || screens.md ? '250px' : '100%',
      }}>
      {floorPlanMapDetails ? (
        <FloorPlanMap
          mapDetails={floorPlanMapDetails}
          mapContainerProps={{
            style: {
              minHeight: '100%',
            },
            className: 'leaflet-map',
          }}
          coordinates={[
            floorPlanMapDetails.currentCoordinates?.y ?? 0,
            floorPlanMapDetails?.currentCoordinates?.x ?? 0,
          ]}
          zoom={-5}
        />
      ) : (
        <LeafletMap
          lat={lat}
          lng={lng}
          mapContainerProps={{
            style: {
              minHeight: '100%',
            },
          }}
        />
      )}
    </Col>
  );
}

const GuageWithoutSensorSpecs = ({ record }: { record: GaugeListType }) => {
  return (
    <Fragment>
      <Row justify="center" style={{ paddingBottom: 12 }}>
        <Col>{record.shortName}</Col>
      </Row>
      <Row justify="center">
        <Col>
          <div
            style={{
              height: 80,
              width: 80,
              border: '8px solid #4697CB',
              borderRadius: 15,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {record.needleValue}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
