import { Fragment, Component } from 'react';
import { Card, Col, Row } from 'antd';

import { manageGaugeData } from '../../../utils';
import { GaugeListType } from '../../../@types';
import { FloorPlanLocationsWithPosition } from '../helpers';
import CompassWithImages from '../../../components-shared/CompassWithImages/CompassWithImages';
import ReactSpeedoMeter from '../../../components-shared/ReactSpeedoMeter';

import cssStyles from '../styles/floorPlans.module.scss';
import { getTwoDecimalPoints } from '../../../shared/helpers';

interface PropsType {
  floorPlanLocationList: FloorPlanLocationsWithPosition[];
  selectedLocationID: string;
  loading: boolean;
  selectedPhenoms: string[];
}

interface StateType {
  gauges: GaugeListType[];
  shouldUpdate: boolean;
}

class GaugeSection extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      gauges: [],
      shouldUpdate: false,
    };
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    const { floorPlanLocationList, selectedLocationID, selectedPhenoms } =
      this.props;
    const { gauges } = this.state;

    if (
      floorPlanLocationList.length > 0 &&
      selectedLocationID &&
      (prevProps.selectedLocationID !== selectedLocationID ||
        selectedPhenoms.length !== prevProps.selectedPhenoms.length)
    ) {
      const selected = floorPlanLocationList.find(
        (el) => el.locationID === selectedLocationID
      );

      if (selected) {
        const phenomDataList = selected.sensorSpecs;
        const latestData = selected.latestData;

        if (latestData && phenomDataList?.length > 0) {
          let tempGaugeData = manageGaugeData({
            phenomList: phenomDataList,
            locationID: selected.locationID,
            gaugeSensors: latestData.Dnum,
          });

          if (selectedPhenoms.length > 0 && tempGaugeData) {
            tempGaugeData = tempGaugeData.filter((el) => {
              const shortName = el.shortName.toUpperCase();
              const hasPhenom = selectedPhenoms.includes(shortName);
              if (hasPhenom) {
                return el;
              }
              return null;
            });
          }

          if (tempGaugeData) {
            this.setState({ gauges: tempGaugeData, shouldUpdate: true });
          } else {
            this.setState({ gauges: [], shouldUpdate: false });
          }
        }
      }
    } else if (!selectedLocationID && gauges.length > 0) {
      this.setState({ gauges: [], shouldUpdate: false });
    }
  }

  render() {
    const { gauges, shouldUpdate } = this.state;
    const { loading } = this.props;

    return (
      <Fragment>
        <Col xs={24} md={24} className="pt-md-3">
          <Card className={cssStyles.gaugeCard}>
            <Row justify="center">
              <Col xs={24}>
                <Row justify="space-around">
                  {gauges.length > 0 && !loading ? (
                    gauges.map((el) => {
                      const actualValue = getTwoDecimalPoints(el.actualValue);
                      if (el.compass) {
                        return (
                          <Fragment key={el.shortName}>
                            <Col>
                              <Row justify="center">
                                <Col>{el.longName}</Col>
                              </Row>
                              <Row justify="center" className="py-2">
                                <Col>
                                  <CompassWithImages
                                    rotateValue={actualValue}
                                  />
                                </Col>
                              </Row>
                              <Row justify="center">
                                <Col>{actualValue}</Col>
                              </Row>
                            </Col>
                          </Fragment>
                        );
                      }
                      return (
                        <Fragment key={el.shortName}>
                          <Col>
                            <Row justify="center">
                              <Col>{el.longName}</Col>
                            </Row>
                            <Row justify="center">
                              <Col>
                                <ReactSpeedoMeter
                                  {...el}
                                  actualValue={actualValue}
                                  speedoMeterProps={{ height: 140 }}
                                  shouldForceRender={shouldUpdate}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Fragment>
                      );
                    })
                  ) : (
                    <Fragment>
                      <Col
                        style={{ display: 'flex', justifyContent: 'center' }}>
                        <span
                          style={{
                            fontSize: '16px',
                            fontStyle: 'italic',
                            color: 'rgb(54, 114, 248)',
                          }}>
                          No recent data
                        </span>
                      </Col>
                    </Fragment>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Fragment>
    );
  }
}

export default GaugeSection;
