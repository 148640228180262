import { Dispatch, Fragment, useCallback, useEffect, useReducer } from 'react';
import { Col, Row } from 'antd';
import update from 'immutability-helper';
import { Fab } from 'react-tiny-fab';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';

import useHttp from '../hooks/use-http';
import {
  reducer,
  initState,
  StateType,
  FloorPlanLocationsWithPosition,
  airQualityFilterValues,
  FloorPlanContext,
} from '../components/FloorPlans/helpers';
import {
  ApiResponseDataType,
  ReducerHookActionType,
  ReduxStoreType,
} from '../@types';
import { RouteComponentProps } from 'react-router';
import { BuildingListType, UserDataType } from '../@types';
import { buildingApi, floorPlanApi } from '../api-services/api-list';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { apiCall } from '../api-services/api';
import { handleNotification } from '../utils/notification-handler';
import { saveFloorPlanDetails } from '../redux/actions/location.actions';
import FilterSection from '../components/FloorPlans/FilterSection';
import useMounted from '../hooks/use-isMounted';
import { updateToken } from '../redux/actions/auth.actions';
import LocationListSection from '../components/FloorPlans/LocationListSection';
import {
  getObjectUpperCase,
  ReducerActionTypes,
  updateImmutably,
} from '../shared/helpers';

type PropsType = RouteComponentProps & {
  userData: Partial<UserDataType>;
};

const FloorPlans = ({ userData }: PropsType) => {
  const [state, dispatchToState]: [
    state: StateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(reducer, initState);
  const { isLoading, sendRequest } = useHttp(true);
  const dispatch = useDispatch();
  const floorPlanIDStore = useSelector(
    (store: ReduxStoreType) => store.location.floorPlanDetails.floorPlanID
  );
  const assetIDStore = useSelector(
    (store: ReduxStoreType) => store.location.floorPlanDetails.assetID
  );
  const buildingIDStore = useSelector(
    (store: ReduxStoreType) => store.location.floorPlanDetails.buildingID
  );

  const { isMounted } = useMounted();

  const {
    floorPlanLocationList,
    mapDetails,
    selectedPhenoms,
    selectedAirQualities,
    phenomCollection,
    selectedLocationID,
    loading,
    initFloorPlanLocationsList,
    isFloorplanChanged,
    buildingList,
  } = state;
  const { token: storeToken } = userData;

  const fetchLocationWithAsset = useCallback(
    async (
      floorPlanID: string,
      assetID: string,
      token: string,
      dispatch: Dispatch<any>
    ) => {
      try {
        const getFloorPlanLocations = floorPlanApi.getFloorPlanLocations(
          undefined,
          {
            floorPlanID,
          }
        );

        const getAsset = floorPlanApi.getAsset(undefined, {
          assetID,
        });

        const responses = await Promise.all([
          apiCall({
            storeToken: token,
            url: getAsset.url,
            method: getAsset.method,
            contentType: getAsset.contentType,
          }),

          apiCall({
            storeToken: token,
            url: getFloorPlanLocations.url,
            method: getFloorPlanLocations.method,
            contentType: getFloorPlanLocations.contentType,
          }),
        ]);

        if (responses && responses.length > 0) {
          const tempFloorPlanLocations: FloorPlanLocationsWithPosition[] =
            responses?.[1]?.data?.data?.map(
              (el: FloorPlanLocationsWithPosition) => ({ ...el, uuid: v4() })
            );
          const tempAsset: string = responses?.[0]?.data;

          return {
            asset: tempAsset,
            floorPlanLocations: tempFloorPlanLocations,
          };
        }
      } catch (error: any) {
        handleNotification('error', error?.data);
        dispatch(updateToken(error?.data));
      }
    },
    []
  );

  useEffect(() => {
    if (storeToken && buildingList.length === 0 && loading) {
      const handleResponse = async (
        response: AxiosResponse<ApiResponseDataType>
      ) => {
        const result = response.data;

        let stateData: Partial<StateType> = {};

        let assetID = assetIDStore;
        let mapWidth;
        let mapHeight;
        let floorPlanID =
          floorPlanIDStore || result?.data?.[0]?.floorplans?.[0]?.floorplanID;
        let buildingID = buildingIDStore || result?.data?.[0].buildingID;

        if (result.data) {
          const data: BuildingListType[] = result.data;
          const temp = data?.map((el) => ({ ...el, uuid: v4() }));
          if (temp && temp.length > 0) {
            stateData = update(stateData, {
              buildingList: { $set: temp },
            });
          }

          const selectedBuilding = temp.find(
            (el) => el.buildingID === buildingID
          );
          if (selectedBuilding) {
            const selectedFloorPlan = selectedBuilding.floorplans.find(
              (el) => el.floorplanID === floorPlanID
            );
            floorPlanID = selectedFloorPlan?.floorplanID;
            assetID = selectedFloorPlan?.assetID ?? '';
            mapWidth = selectedFloorPlan?.assetWidth;
            mapHeight = selectedFloorPlan?.assetHeight;
          }

          let results;

          if (floorPlanID && assetID) {
            results = await fetchLocationWithAsset(
              floorPlanID,
              assetID,
              storeToken,
              dispatch
            );
          }

          if (results) {
            const { asset, floorPlanLocations } = results;
            if (asset) {
              stateData = update(stateData, {
                $merge: {
                  mapDetails: {
                    image: asset,
                    width: mapWidth,
                    height: mapHeight,
                  },
                },
              });
            }
            if (floorPlanLocations) {
              const result = onManageFloorplanLocations(floorPlanLocations);

              stateData = update(stateData, {
                floorPlanLocationList: { $set: result.floorPlanLocations },
                initFloorPlanLocationsList: { $set: result.floorPlanLocations },
                phenomCollection: { $set: result.phenomCollection },
                selectedLocationID: {
                  $set: result.floorPlanLocations[0].locationID,
                },
              });
            }
          }
        }

        isMounted &&
          dispatchToState({
            type: ReducerActionTypes.SetState,
            payload: { ...stateData, loading: false },
          });
        isMounted &&
          dispatch(saveFloorPlanDetails({ floorPlanID, assetID, buildingID }));
      };
      const getBuildings = buildingApi.getBuildings();
      isMounted && sendRequest({ ...getBuildings, storeToken }, handleResponse);
    }
  }, [
    assetIDStore,
    floorPlanIDStore,
    dispatch,
    sendRequest,
    storeToken,
    isMounted,
    fetchLocationWithAsset,
    loading,
    buildingList.length,
    buildingIDStore,
  ]);

  useEffect(() => {
    if (isFloorplanChanged) {
      const selectedBuilding = buildingList.find(
        (el) => el.buildingID === buildingIDStore
      );

      const selected = selectedBuilding?.floorplans.find(
        (el) => el.floorplanID === floorPlanIDStore
      );

      let stateData: Partial<StateType> = {};

      let results;

      if (selected && storeToken && selected?.assetID) {
        (async () => {
          results = await fetchLocationWithAsset(
            floorPlanIDStore,
            selected?.assetID,
            storeToken,
            dispatch
          );

          if (results) {
            const { asset, floorPlanLocations } = results;
            if (asset) {
              stateData = update(stateData, {
                $merge: {
                  mapDetails: {
                    image: asset,
                    width: selected.assetWidth,
                    height: selected.assetHeight,
                  },
                },
              });
            }

            if (floorPlanLocations && floorPlanLocations.length > 0) {
              const result = onManageFloorplanLocations(floorPlanLocations);
              stateData = update(stateData, {
                floorPlanLocationList: { $set: result.floorPlanLocations },
                initFloorPlanLocationsList: { $set: result.floorPlanLocations },
                phenomCollection: { $set: result.phenomCollection },
                selectedLocationID: {
                  $set: result.floorPlanLocations[0].locationID,
                },
              });
            }
          }

          isMounted &&
            dispatchToState({
              type: ReducerActionTypes.SetState,
              payload: {
                ...stateData,
                loading: false,
                isFloorplanChanged: false,
              },
            });
        })();
      }
    }
  }, [
    buildingIDStore,
    buildingList,
    dispatch,
    fetchLocationWithAsset,
    floorPlanIDStore,
    isFloorplanChanged,
    isMounted,
    storeToken,
  ]);

  const onFloorPlanSelect = useCallback(
    async (value: string) => {
      const selectedBuilding = buildingList.find((el) =>
        el.floorplans.find((elem) => elem.floorplanID === value)
      );

      isMounted &&
        dispatch(
          saveFloorPlanDetails({
            floorPlanID: value,
            buildingID: selectedBuilding?.buildingID,
          })
        );
      isMounted &&
        dispatchToState({
          type: ReducerActionTypes.SetState,
          payload: {
            loading: true,
            floorPlanLocationList: [],
            initFloorPlanLocationsList: [],
            isFloorplanChanged: true,
          },
        });
    },
    [buildingList, dispatch, isMounted]
  );

  const onPhenomSelect = useCallback(
    (value: string[]) => {
      isMounted &&
        dispatchToState({
          type: ReducerActionTypes.SetState,
          payload: { selectedPhenoms: value },
        });
    },
    [isMounted]
  );

  const onPhenomClear = useCallback(() => {
    isMounted &&
      dispatchToState({
        type: ReducerActionTypes.SetState,
        payload: { selectedPhenoms: [] },
      });
  }, [isMounted]);

  const onAirQualitySelect = useCallback(
    (value: string[]) => {
      let tempData = [...initFloorPlanLocationsList];
      if (value.length > 0) {
        tempData = floorPlanLocationList.filter((el) => {
          if (el.colorName && value.includes(el.colorName)) {
            return el;
          }
          return null;
        });
      }

      isMounted &&
        dispatchToState({
          type: ReducerActionTypes.SetState,
          payload: {
            selectedAirQualities: value,
            floorPlanLocationList: tempData,
          },
        });
    },
    [floorPlanLocationList, initFloorPlanLocationsList, isMounted]
  );

  const onAirQualityClear = useCallback(() => {
    isMounted &&
      dispatchToState({
        type: ReducerActionTypes.SetState,
        payload: { selectedAirQualities: [] },
      });
  }, [isMounted]);

  const onLocationSelect = useCallback((value: string) => {
    dispatchToState({
      type: ReducerActionTypes.SetLocationID,
      payload: { selectedLocationID: value },
    });
  }, []);

  const onUpArrowClick = () => {
    if (floorPlanIDStore) {
      const currentBuildingIndex = buildingList.findIndex((el) =>
        el.floorplans.find((elem) => elem.floorplanID === floorPlanIDStore)
      );

      if (currentBuildingIndex !== -1) {
        const currentBuildingData = buildingList[currentBuildingIndex];
        const currentFloorPlanIndex = currentBuildingData.floorplans.findIndex(
          (el) => el.floorplanID === floorPlanIDStore
        );

        if (currentFloorPlanIndex !== -1) {
          const currentBuildingLength = buildingList.length;

          if (currentBuildingLength > 0) {
            let floorPlanID = floorPlanIDStore;
            if (currentBuildingIndex > 0 && currentFloorPlanIndex > 0) {
              floorPlanID =
                buildingList?.[currentBuildingIndex]?.floorplans?.[
                  currentFloorPlanIndex - 1
                ]?.floorplanID;
            } else if (
              currentBuildingIndex > 0 &&
              currentFloorPlanIndex === 0
            ) {
              floorPlanID =
                buildingList?.[currentBuildingIndex - 1]?.floorplans?.[
                  buildingList[currentBuildingIndex - 1].floorplans.length - 1
                ]?.floorplanID;
            } else if (
              currentBuildingIndex === 0 &&
              currentFloorPlanIndex > 0
            ) {
              floorPlanID =
                buildingList?.[currentBuildingIndex]?.floorplans?.[
                  currentFloorPlanIndex - 1
                ]?.floorplanID;
            } else if (
              currentBuildingIndex === 0 &&
              currentFloorPlanIndex === 0
            ) {
              floorPlanID =
                buildingList?.[currentBuildingLength - 1]?.floorplans?.[
                  buildingList[currentBuildingLength - 1].floorplans.length - 1
                ]?.floorplanID;
            }

            onFloorPlanSelect(floorPlanID);
          }
        }
      }
    }
  };

  const onDownArrowClick = () => {
    if (floorPlanIDStore) {
      const currentBuildingIndex = buildingList.findIndex((el) =>
        el.floorplans.find((elem) => elem.floorplanID === floorPlanIDStore)
      );

      if (currentBuildingIndex !== -1) {
        const currentBuildingData = buildingList[currentBuildingIndex];
        const currentFloorPlanIndex = currentBuildingData.floorplans.findIndex(
          (el) => el.floorplanID === floorPlanIDStore
        );

        if (currentFloorPlanIndex !== -1) {
          const currentBuildingLength = buildingList.length;

          if (currentBuildingLength > 0) {
            const currentFloorPlanLength =
              currentBuildingData?.floorplans &&
              currentBuildingData.floorplans.length;

            if (currentFloorPlanLength > 0) {
              let floorPlanID = floorPlanIDStore;
              if (
                currentBuildingLength > currentBuildingIndex + 1 &&
                currentFloorPlanLength > currentFloorPlanIndex + 1
              ) {
                floorPlanID =
                  buildingList?.[currentBuildingIndex]?.floorplans?.[
                    currentFloorPlanIndex + 1
                  ]?.floorplanID;
              } else if (
                currentBuildingLength > currentBuildingIndex + 1 &&
                currentFloorPlanLength === currentFloorPlanIndex + 1
              ) {
                floorPlanID =
                  buildingList?.[currentBuildingIndex + 1]?.floorplans?.[0]
                    ?.floorplanID;
              } else if (
                currentBuildingLength === currentBuildingIndex + 1 &&
                currentFloorPlanLength > currentFloorPlanIndex + 1
              ) {
                floorPlanID =
                  buildingList?.[currentBuildingIndex]?.floorplans?.[
                    currentFloorPlanIndex + 1
                  ]?.floorplanID;
              } else if (
                currentBuildingLength === currentBuildingIndex + 1 &&
                currentFloorPlanLength === currentFloorPlanIndex + 1
              ) {
                floorPlanID = buildingList?.[0]?.floorplans?.[0]?.floorplanID;
              }

              onFloorPlanSelect(floorPlanID);
            }
          }
        }
      }
    }
  };

  return (
    <Fragment>
      <FloorPlanContext.Provider
        value={{
          selectedPhenoms: selectedPhenoms,
          buildingList,
        }}>
        <Row className="p-3" style={{ minHeight: '100vh' }}>
          <Col xs={24}>
            <FilterSection
              loading={isLoading || loading}
              selectedFloorPlan={floorPlanIDStore}
              selectedAirQualities={selectedAirQualities}
              selectedPhenoms={selectedPhenoms}
              onFloorPlanSelect={onFloorPlanSelect}
              onAirQualitySelect={onAirQualitySelect}
              onPhenomSelect={onPhenomSelect}
              phenomCollection={phenomCollection}
              onPhenomClear={onPhenomClear}
              onAirQualityClear={onAirQualityClear}
              buildingList={buildingList}
            />

            <LocationListSection
              loading={isLoading || loading}
              floorPlanLocationList={floorPlanLocationList}
              mapDetails={mapDetails}
              onLocationSelect={onLocationSelect}
              selectedLocationID={selectedLocationID}
              selectedPhenoms={selectedPhenoms}
            />
          </Col>
        </Row>

        <Fab
          mainButtonStyles={{
            backgroundColor: '#1890ff',
            color: '#fff',
            fontSize: 16,
          }}
          style={{ bottom: 88, right: 24 }}
          icon={
            <Fragment>
              <Row>
                <Col xs={24}>
                  <BsFillCaretUpFill />
                </Col>
              </Row>
            </Fragment>
          }
          event={'click'}
          alwaysShowTitle={false}
          onClick={onUpArrowClick}></Fab>

        <Fab
          mainButtonStyles={{
            backgroundColor: '#1890ff',
            color: '#fff',
            fontSize: 16,
          }}
          icon={
            <Fragment>
              <Row align="middle">
                <Col xs={24}>
                  <BsFillCaretDownFill />
                </Col>
              </Row>
            </Fragment>
          }
          event={'click'}
          alwaysShowTitle={false}
          onClick={onDownArrowClick}></Fab>
      </FloorPlanContext.Provider>
    </Fragment>
  );
};

export default FloorPlans;

const onManageFloorplanLocations = (data: FloorPlanLocationsWithPosition[]) => {
  let phenomCollection: string[] = [];
  const keys = Object.keys(airQualityFilterValues);
  if (data && data.length > 0) {
    data = data.map((el) => {
      let tempObj = { ...el };
      if (tempObj?.latestData?.Dnum) {
        tempObj = updateImmutably(tempObj, {
          latestData: {
            Dnum: { $set: getObjectUpperCase(tempObj.latestData.Dnum) },
          },
        });
        const tempSensor = Object.keys(tempObj.latestData.Dnum);
        phenomCollection.push(...tempSensor);
      }

      const sensorList = tempObj?.sensorSpecs;
      let colorCode = '';
      let colorName = '';
      if (tempObj.latestData.Dnum && sensorList && sensorList.length > 0) {
        sensorList.forEach((ph) => {
          const sensorValue =
            tempObj.latestData.Dnum?.[ph?.shortName?.toUpperCase()];
          if (sensorValue) {
            if (Number(sensorValue) >= Number(ph.redPoint)) {
              colorCode = '#ff5454';
              colorName = keys[0];
            } else if (Number(sensorValue) >= Number(ph.amberPoint)) {
              colorCode = '#efd613';
              colorName = keys[1];
            } else {
              colorCode = '#3dcc5b';
              colorName = keys[2];
            }
          }
        });
      }
      tempObj = { ...tempObj, colorCode, colorName };
      return { ...tempObj };
    });
  }

  return {
    phenomCollection: Array.from(new Set(phenomCollection)),
    floorPlanLocations: data,
  };
};
