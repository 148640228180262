import axios from 'axios';
import { Fragment, Component, CSSProperties } from 'react';
import { RouteComponentProps } from 'react-router';
import update from 'immutability-helper';

import { LocationListType, UserDataType } from '../@types';
import { updateToken } from '../redux/actions/auth.actions';
import { connect, ConnectedProps } from 'react-redux';
import { locationApi } from '../api-services/api-list';
import { apiCall } from '../api-services/api';
import { handleNotification } from '../utils/notification-handler';
import { ChartListType, GaugeListType, ReduxStoreType } from '../@types';
import { manageChartData, manageGaugeData } from '../utils';
import Gauge from '../components/LocationCarousel/Gauge';
import { Col, Row } from 'antd';
import Chart from '../components/LocationCarousel/Chart';
import SettingsModal from '../components-shared/SettingsModal';
import withBreakpoint from '../HOC/withBreakpoint';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import cssStyles from '../components/LocationCarousel/styles/locationCarousel.module.scss';

type PropsType = PropsFromRedux &
  RouteComponentProps<{ locationID: string }, any, any> & {
    userData: Partial<UserDataType>;
    screens: Partial<Record<Breakpoint, boolean>>;
  };

type StateType = {
  showSettingsDialog: boolean;
  locationDetails: Partial<LocationListType>;
  loadingHeader: boolean;
  loadingChart: boolean;
  loadingGauge: boolean;
  chartList: ChartListType[];
  gaugeList: GaugeListType[];
};

class LocationCarousel extends Component<PropsType, StateType> {
  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();
  locationID: string | undefined;

  constructor(props: PropsType) {
    super(props);

    this.state = {
      showSettingsDialog: false,
      locationDetails: props?.location?.state?.locationData || {},
      loadingHeader: !props?.location?.state?.locationData?.locationID,
      loadingChart: true,
      loadingGauge: true,
      gaugeList: [],
      chartList: [],
    };

    this.locationID = props?.match?.params?.locationID;
  }

  componentDidMount() {
    this._isMounted = true;

    this.fetchLocationDetails();

    document.addEventListener('keydown', this.handleEscapeEvent, false);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>, callback?: () => void) => {
    this._isMounted &&
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            ...data,
          };
        },
        () => {
          callback?.();
        }
      );
  };

  handleEscapeEvent = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.handleState({
        showSettingsDialog: true,
      });
    }
  };

  fetchLocationDetails = async () => {
    let stateData: Partial<StateType> = {};
    if (this.locationID) {
      const { userData, updateToken } = this.props;
      try {
        const { url, method, contentType } = locationApi.getLocationDetails(
          undefined,
          {
            locationID: this.locationID,
          }
        );

        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const result = response?.data;
        updateToken(result);
        if (result?.status === 'ok') {
          stateData = update(stateData, {
            locationDetails: { $set: result.data || {} },
          });
          stateData = update(stateData, { loadingHeader: { $set: false } });
          this.fetchLocationLatest(stateData);
        } else {
          stateData = update(stateData, { loadingHeader: { $set: false } });
          this._isMounted && handleNotification('error', result);
        }
      } catch (error: any) {
        stateData = update(stateData, { loadingHeader: { $set: false } });
        this._isMounted && handleNotification('error', error?.data);
        updateToken(error?.data);
      }

      this.handleState({ ...stateData });
    }
  };

  fetchLocationLatest = async (stateData: Partial<StateType>) => {
    const { userData, updateToken } = this.props;
    if (stateData?.locationDetails?.sensorSpecs && this.locationID) {
      try {
        const { url, method, contentType } = locationApi.getLocationLatest(
          undefined,
          {
            locationID: this.locationID,
          }
        );

        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });

        const result = response?.data;
        updateToken(result);

        if (result?.status === 'ok') {
          const gaugeSensors = result.data?.latestData?.Dnum;
          if (gaugeSensors) {
            const tempGaugeData = manageGaugeData({
              phenomList: stateData.locationDetails.sensorSpecs,
              gaugeSensors,
              locationID: this.locationID,
            });

            if (tempGaugeData) {
              stateData = update(stateData, {
                gaugeList: { $set: tempGaugeData },
              });
            }
          }
        } else {
          this._isMounted && handleNotification('error', result);
        }
      } catch (error: any) {
        this._isMounted && handleNotification('error', error?.data);
        updateToken(error?.data);
      }
    }

    stateData = update(stateData, { loadingGauge: { $set: false } });

    this.handleState({ ...stateData }, () => {
      this.fetchLocationAverages(stateData);
    });
  };

  fetchLocationAverages = async (stateData?: Partial<StateType>) => {
    if (!stateData) {
      stateData = { ...this.state };
    }

    if (this.locationID && stateData?.locationDetails?.sensorSpecs) {
      const { userData, updateToken, settingsDialogFormData } = this.props;

      let paramsData: {
        lasthours: string;
      } = { lasthours: settingsDialogFormData.timeSpan.value };

      try {
        const { url, method, contentType, params } =
          locationApi.getLocationAverages(
            {
              ...paramsData,
            },
            { locationID: this.locationID }
          );

        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          params,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });

        const result = response?.data;

        updateToken(result);

        if (result?.status === 'ok') {
          if (result.data && stateData?.locationDetails?.sensorSpecs) {
            const tempData = manageChartData({
              phenomList: stateData?.locationDetails?.sensorSpecs,
              locationID: result.data.locationID,
              locationAveragesList: result.data.timeSeriesData,
            });

            if (tempData) {
              stateData = update(stateData, {
                chartList: { $set: tempData },
              });
            }
          }
        } else {
          this._isMounted && handleNotification('error', result);
        }
      } catch (error: any) {
        this._isMounted && handleNotification('error', error?.data);
        updateToken(error?.data);
      }
    }

    stateData = update(stateData, { loadingChart: { $set: false } });
    this.handleState({ ...stateData });
  };

  handleSettingsDialog = () => {
    this._isMounted &&
      this.setState((prev) => ({
        ...prev,
        showSettingsDialog: !prev.showSettingsDialog,
      }));
  };

  render() {
    const {
      gaugeList,
      loadingGauge,
      locationDetails,
      loadingChart,
      chartList,
      showSettingsDialog,
    } = this.state;

    const { settingsDialogFormData, screens } = this.props;

    const styles: CSSProperties = {};
    if (screens.xl) {
      styles.overflow = 'hidden';
    }

    return (
      <Fragment>
        <Row style={{ ...styles }} className={`p-3 ${cssStyles.root}`}>
          <Col xs={24}>
            <Gauge
              gaugeList={gaugeList}
              loading={loadingGauge}
              locationDetails={locationDetails}
            />
            <Chart
              loading={loadingChart}
              chartList={chartList}
              timeSpan={settingsDialogFormData.timeSpan.value}
              updateInterval={settingsDialogFormData.updateInterval.value}
              pauseInterval={settingsDialogFormData.pauseInterval.value}
              transitionType={settingsDialogFormData.transitionType.value}
            />
          </Col>
        </Row>

        {showSettingsDialog && (
          <SettingsModal
            showModal={showSettingsDialog}
            closeModal={this.handleSettingsDialog}
            onSaveSettingsDialog={this.handleSettingsDialog}
          />
        )}
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};

function mapStateToProps(state: ReduxStoreType) {
  return {
    settingsDialogFormData: state.location.settingsDialogFormData,
  };
}

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withBreakpoint(LocationCarousel));
