import update from 'immutability-helper';
import { GaugeListType, ReducerHookActionType } from '../../../../@types';
import { ReducerActionTypes } from '../../../../shared/helpers';

export const initState: StateType = {
  loading: true,
  gaugeList: [],
};

export const reducer = (state: StateType, action: ReducerHookActionType) => {
  switch (action.type) {
    case ReducerActionTypes.SetState: {
      const updatedState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...updatedState };
    }

    default:
      return { ...state };
  }
};

export type StateType = {
  loading: boolean;
  gaugeList: GaugeListType[];
};
