import * as Leaflet from 'leaflet';
import { MapBoundsType, MapCoordinatesType } from '../../@types';

export const customCRSSimple = Leaflet.extend({}, Leaflet.CRS.Simple, {
  projection: Leaflet.Projection.LonLat,
  transformation: new Leaflet.Transformation(1, 0, 1, 0),
});

export function getBoundsUnproject(
  map: Leaflet.Map,
  svgImageHeight: number,
  svgImageWidth: number,
  unprojectZoom: number
) {
  const southWest = map.unproject([0, svgImageHeight], unprojectZoom);
  const northEast = map.unproject([svgImageWidth, 0], unprojectZoom);
  const bounds = new Leaflet.LatLngBounds(southWest, northEast);
  return bounds;
}

/**
 *  To get Leaflet Map Point
 * @param {{x: Number, y: Number}} customCoordinates Coordinates value which is to be converted to Leaflet Point
 * @returns {Leaflet.Point} Leaflet Point is returned
 */
export function getMapPoint(customCoordinates: { x: number; y: number }) {
  const point = Leaflet.point(customCoordinates.x, customCoordinates.y);
  return point;
}

export function getMapBoundsAndCenter({
  height,
  width,
}: {
  height: number;
  width: number;
}): { bounds: MapBoundsType; centre: MapCoordinatesType } {
  const topLeft: [number, number] = [0, 0];
  const bottomRight: [number, number] = [height, width];
  const bounds: MapBoundsType = [topLeft, bottomRight];
  const centre: MapCoordinatesType = [bottomRight[0] / 2, bottomRight[1] / 2];
  return { bounds, centre };
}

export const CRS_CUSTOM_SCALE = Leaflet.extend({}, Leaflet.CRS.Simple, {
  projection: Leaflet.Projection.LonLat,
  transformation: new Leaflet.Transformation(0.125, 0, 0.125, 0),
  scale: function (zoom: any) {
    return Math.pow(2, zoom);
  },

  zoom: function (scale: any) {
    return Math.log(scale) / Math.LN2;
  },

  distance: function (latlng1: any, latlng2: any) {
    const dx = latlng2.lng - latlng1.lng,
      dy = latlng2.lat - latlng1.lat;

    return Math.sqrt(dx * dx + dy * dy);
  },
  infinite: true,
});
