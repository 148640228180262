import { Fragment } from 'react';
import { Card, Col, Row, Select } from 'antd';
import { AiOutlineClear } from 'react-icons/ai';

import { BuildingListType } from '../../../@types';
import { airQualityFilterValues } from '../helpers';

const { Option, OptGroup } = Select;

interface PropsType {
  loading: boolean;
  selectedFloorPlan: string;
  selectedPhenoms: string[];
  selectedAirQualities: string[];
  onFloorPlanSelect: (value: string) => void;
  onPhenomSelect: (value: string[]) => void;
  onAirQualitySelect: (value: string[]) => void;
  phenomCollection: string[];
  onPhenomClear: () => void;
  onAirQualityClear: () => void;
  buildingList: BuildingListType[];
}

const FilterSection = ({
  loading,
  selectedFloorPlan,
  onFloorPlanSelect,
  selectedAirQualities,
  selectedPhenoms,
  onAirQualitySelect,
  onPhenomSelect,
  phenomCollection,
  onAirQualityClear,
  onPhenomClear,
  buildingList,
}: PropsType) => {
  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card>
            <Row
              align="middle"
              gutter={[{ md: 12 }, { xs: 12, sm: 12, md: 0 }]}>
              <Col xs={24} md={8}>
                <Select
                  disabled={loading}
                  value={selectedFloorPlan}
                  style={{ width: '100%' }}
                  onChange={onFloorPlanSelect}
                  size={'large'}>
                  {buildingList.map((el, idx) => {
                    return (
                      <Fragment key={idx}>
                        <OptGroup label={el.name}>
                          {el.floorplans.map((fp, i) => {
                            return (
                              <Fragment key={i}>
                                <Option value={fp.floorplanID}>
                                  {fp.name}
                                </Option>
                              </Fragment>
                            );
                          })}
                        </OptGroup>
                      </Fragment>
                    );
                  })}
                </Select>
                {/* {buildingList.length > 0 && (
                  <Menu
                    mode={'horizontal'}
                    openKeys={openMenuKeys}
                    onOpenChange={onMenuOpenChange}
                    onClick={onMenuItemClicked}
                    selectedKeys={selectedMenuItemKeys}
                    style={{ width: '100%' }}
                    triggerSubMenuAction="click">
                    {buildingList.map((el, idx) => {
                      return (
                        <Fragment key={el.uuid}>
                          <SubMenu
                            key={`sub_${idx + 1}`}
                            icon={<BiBuildingHouse />}
                            title={el.name}>
                            {el.floorplans && (
                              <Fragment>
                                {el.floorplans.map((fp, i) => {
                                  return (
                                    <Fragment key={i}>
                                      <Menu.Item
                                        key={`sub_${idx + 1}_item_${i + 1}`}
                                        onClick={() => onLocationSelect(fp)}>
                                        {fp.name}
                                      </Menu.Item>
                                    </Fragment>
                                  );
                                })}
                              </Fragment>
                            )}
                          </SubMenu>
                        </Fragment>
                      );
                    })}
                  </Menu>
                )} */}
              </Col>

              <Col xs={24} md={8}>
                <Select
                  allowClear
                  clearIcon={
                    <AiOutlineClear
                      size="1.2em"
                      color="#2880ca"
                      onClick={onPhenomClear}
                    />
                  }
                  mode="multiple"
                  disabled={loading}
                  size={'large'}
                  value={selectedPhenoms}
                  onChange={onPhenomSelect}
                  style={{ width: '100%' }}
                  placeholder="Select Phenom to filter">
                  {phenomCollection.map((el) => (
                    <Fragment key={el}>
                      <Select.Option key={el} value={el}>
                        {el}
                      </Select.Option>
                    </Fragment>
                  ))}
                </Select>
              </Col>

              <Col xs={24} md={8}>
                <Select
                  allowClear
                  clearIcon={
                    <AiOutlineClear
                      size="1.2em"
                      color="#2880ca"
                      onClick={onAirQualityClear}
                    />
                  }
                  mode="multiple"
                  disabled={loading}
                  size={'large'}
                  value={selectedAirQualities}
                  onChange={onAirQualitySelect}
                  style={{ width: '100%' }}
                  placeholder="Select Air Quality to filter">
                  {Object.entries(airQualityFilterValues).map(
                    ([key, value]) => (
                      <Fragment key={key}>
                        <Select.Option value={key}>{value}</Select.Option>
                      </Fragment>
                    )
                  )}
                </Select>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default FilterSection;
