import { icon } from 'leaflet';

import MarkerIconBlue from '../assets/images/map/marker-icon-blue.png';
import MarkerIconGreen from '../assets/images/map/marker_green.png';
import MarkerIconGrey from '../assets/images/map/marker_grey.png';
import MarkerIconAmber from '../assets/images/map/marker_amber.png';
import MarkerIconRed from '../assets/images/map/marker_red.png';
import MarkerShadow from '../assets/images/map/marker-shadow.png';

export const getLeafletIcon = (
  color: 'amber' | 'red' | 'green' | 'grey' | 'blue' = 'blue'
) => {
  let leafletIcon = MarkerIconBlue;
  if (color === 'amber') {
    leafletIcon = MarkerIconAmber;
  } else if (color === 'red') {
    leafletIcon = MarkerIconRed;
  } else if (color === 'green') {
    leafletIcon = MarkerIconGreen;
  } else if (color === 'grey') {
    leafletIcon = MarkerIconGrey;
  }

  return icon({
    iconUrl: leafletIcon,
    shadowUrl: MarkerShadow,
    iconSize: [25, 41],
    shadowSize: [41, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
  });
};
