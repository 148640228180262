import { Fragment, PureComponent } from 'react';
import { FaDownload } from 'react-icons/fa';
import update from 'immutability-helper';
import axios from 'axios';

import { UserDataType } from '../../@types';
import Modal from 'antd/lib/modal/Modal';
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Switch,
  TimePicker,
} from 'antd';
import { getZuluFormatUTC } from '../../utils';
import { locationApi } from '../../api-services/api-list';
import { handleNotification } from '../../utils/notification-handler';
import timeZones from '../../data/timeZones.json';
import { ChartParamsType } from '../../@types';
const { Option } = Select;

type StateType = {
  period: string;
  unit: 'days' | 'minutes' | 'hours';
  splitInterval: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  selectedTimeZone: string;

  showModal: boolean;
  switchSelector: boolean;
  loading: boolean;
};

type PropsType = {
  locationID: string;
  userData: Partial<UserDataType>;
};

const initState: StateType = {
  period: '10',
  unit: 'hours',
  splitInterval: '',
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  selectedTimeZone: timeZones[0],

  showModal: false,
  switchSelector: true,
  loading: false,
};

class DownloadModal extends PureComponent<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = { ...initState };
  }

  handleResetState = () => {
    return update(this.state, {
      period: { $set: initState.period },
      unit: { $set: initState.unit },
      splitInterval: { $set: initState.splitInterval },
      startDate: { $set: initState.startDate },
      startTime: { $set: initState.startTime },
      endDate: { $set: initState.endDate },
      endTime: { $set: initState.endTime },
      showModal: { $set: initState.showModal },
      switchSelector: { $set: initState.switchSelector },
    });
  };

  onModalOpen = () => {
    this.setState({ showModal: true });
  };

  onModalClose = () => {
    const stateData: StateType = this.handleResetState();
    this.setState((prev) => ({ ...prev, ...stateData }));
  };

  onDownloadClick = async () => {
    const { locationID, userData } = this.props;
    const {
      unit,
      period,
      splitInterval,
      startDate,
      startTime,
      endDate,
      endTime,
      switchSelector,
      selectedTimeZone,
    } = this.state;

    let data: ChartParamsType = {
      wf_tkn: userData.token,
      timezone: selectedTimeZone,
    };

    if (switchSelector) {
      if (unit === 'hours') {
        data = update(data, { lasthours: { $set: period } });
      } else if (unit === 'days') {
        data = update(data, { lastdays: { $set: period } });
      } else if (unit === 'minutes') {
        data = update(data, { lastmins: { $set: period } });
      }
    } else {
      data = update(data, {
        from: { $set: getZuluFormatUTC(startDate, startTime) },
      });
      data = update(data, { to: { $set: getZuluFormatUTC(endDate, endTime) } });
    }

    if (splitInterval) {
      data = update(data, { split: { $set: splitInterval } });
    }

    try {
      this.setState({ loading: true });

      const { url, params } = locationApi.getLocationAveragesCSV(
        { ...data },
        {
          locationID,
        }
      );

      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        params,
      });

      this.setState({ loading: false });

      if (response.data.size) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
      } else {
        handleNotification('error', { message: 'No data Found' });
      }
    } catch (error: any) {
      this.setState({ loading: false });

      handleNotification('error', error?.response?.data);
    }

    // let tempData = [];
    // let key: keyof typeof data;
    // for (key in data) {
    //   if (key) {
    //     const encodedKey = encodeURIComponent(key);
    //     const encodedValue = encodeURIComponent(data[key]!);
    //     tempData.push(encodedKey + '=' + encodedValue);
    //   }
    // }
    // const formBody: string = tempData.join('&');

    // try {
    //   const { url, params } = locationApi.getLocationAveragesCSV(formBody, {
    //     locationID,
    //   });

    //   const fullUrl = `${url}?${params}`;
    //   window.location.href = fullUrl;
    // } catch (error) {
    //   handleNotification('error', error);
    // }
  };

  onSwitchSelector = (value: boolean) => {
    this.setState({ switchSelector: value });
  };

  onInputChange = (name: string, value: any) => {
    const stateData: StateType = update(this.state, {
      [name]: { $set: value },
    });
    this.setState((prev) => ({ ...prev, ...stateData }));
  };

  render() {
    const {
      showModal,
      switchSelector,
      period,
      unit,
      splitInterval,
      startDate,
      startTime,
      endDate,
      endTime,
      loading,
      selectedTimeZone,
    } = this.state;

    let isDownloadDisabled = false;
    if (switchSelector && !period) {
      isDownloadDisabled = true;
    } else if (!switchSelector && (!startDate || !endDate)) {
      isDownloadDisabled = true;
    }

    return (
      <Fragment>
        <FaDownload
          size="1.2em"
          style={{ color: '#007bff', cursor: 'pointer' }}
          onClick={this.onModalOpen}
        />
        {showModal && (
          <Fragment>
            <Modal
              title="CSV Download"
              visible={showModal}
              onCancel={this.onModalClose}
              closable={false}
              footer={[
                <Button key="1" htmlType="button" onClick={this.onModalClose}>
                  Cancel
                </Button>,
                <Button
                  loading={loading}
                  key="2"
                  disabled={isDownloadDisabled}
                  htmlType="button"
                  onClick={this.onDownloadClick}
                  type="primary">
                  Download
                </Button>,
              ]}>
              <Row>
                <Col>Control Type</Col>
              </Row>
              <Row className="py-2">
                <Col>
                  <Switch
                    defaultChecked={switchSelector}
                    onChange={this.onSwitchSelector}
                  />
                  &nbsp;&nbsp;
                  {switchSelector ? 'TimeSpan' : 'Date/Time'}
                </Col>
              </Row>
              {switchSelector ? (
                <TimeSpan
                  period={period}
                  unit={unit}
                  onInputChange={this.onInputChange}
                />
              ) : (
                <DateTime
                  onInputChange={this.onInputChange}
                  startDate={startDate}
                  startTime={startTime}
                  endDate={endDate}
                  endTime={endTime}
                />
              )}
              <Row className="py-2">
                <Col xs={24} lg={12} className="pr-lg-1">
                  <label htmlFor="selectedTimeZone">Time Zones</label>
                  <Select
                    style={{ width: '100%' }}
                    size="large"
                    value={selectedTimeZone}
                    onChange={(value) =>
                      this.onInputChange('selectedTimeZone', value)
                    }>
                    {timeZones.map((el, idx) => (
                      <Option value={el} key={idx}>
                        {el}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col xs={24} lg={12} className="pt-2 pt-lg-0 pl-lg-1">
                  <label htmlFor="splitInterval">Split Interval(mins):</label>
                  <Input
                    style={{ width: '100%' }}
                    size="large"
                    value={splitInterval}
                    name="splitInterval"
                    onChange={(event) => {
                      if (!isNaN(Number(event.currentTarget.value))) {
                        return this.onInputChange(
                          event.currentTarget.name,
                          event.currentTarget.value
                        );
                      }
                      return;
                    }}
                  />
                </Col>
              </Row>
            </Modal>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default DownloadModal;

const unitValues = [
  {
    text: 'Days',
    value: 'days',
  },
  {
    text: 'Hours',
    value: 'hours',
  },
  {
    text: 'Minutes',
    value: 'minutes',
  },
];

function TimeSpan({
  period,
  unit,
  onInputChange,
}: {
  period: string;
  unit: string;
  onInputChange: (name: string, value: any) => void;
}) {
  return (
    <Fragment>
      <Row className="py-2">
        <Col xs={24} lg={12} className="pr-lg-1">
          <label htmlFor="period">Period</label>
          <Input
            style={{ width: '100%' }}
            size="large"
            value={period}
            name="period"
            onChange={(event) => {
              if (!isNaN(Number(event.currentTarget.value))) {
                return onInputChange(
                  event.currentTarget.name,
                  event.currentTarget.value
                );
              }
              return;
            }}
          />
        </Col>
        <Col xs={24} lg={12} className="pt-2 pt-lg-0 pl-lg-1">
          <label htmlFor="unit">Unit</label>
          <Select
            style={{ width: '100%' }}
            size="large"
            value={unit}
            onChange={(value) => onInputChange('unit', value)}>
            {unitValues.map(({ text, value }) => (
              <Option value={value} key={value}>
                {text}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Fragment>
  );
}

function DateTime({
  startDate,
  startTime,
  endDate,
  endTime,
  onInputChange,
}: {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  onInputChange: (name: string, value: any) => void;
}) {
  return (
    <Fragment>
      <Row className="py-2">
        <Col xs={24} lg={12} className="pr-lg-1">
          <label htmlFor="startDate">Start Date</label>
          <DatePicker
            style={{ width: '100%' }}
            size="large"
            name="startDate"
            onChange={(date, dateString) =>
              onInputChange('startDate', dateString)
            }
          />
        </Col>
        <Col xs={24} lg={12} className="pt-2 pt-lg-0 pl-lg-1">
          <label htmlFor="startTime">Start Time</label>
          <TimePicker
            style={{ width: '100%' }}
            size="large"
            name="startTime"
            onChange={(time, timeString) =>
              onInputChange('startTime', timeString)
            }
          />
        </Col>
      </Row>
      <Row className="py-2">
        <Col xs={24} lg={12} className="pr-lg-1">
          <label htmlFor="endDate">End Date</label>
          <DatePicker
            style={{ width: '100%' }}
            size="large"
            name="endDate"
            onChange={(date, dateString) =>
              onInputChange('endDate', dateString)
            }
          />
        </Col>
        <Col xs={24} lg={12} className="pt-2 pt-lg-0 pl-lg-1">
          <label htmlFor="endTime">End Time</label>
          <TimePicker
            style={{ width: '100%' }}
            size="large"
            name="endTime"
            onChange={(time, timeString) =>
              onInputChange('endTime', timeString)
            }
          />
        </Col>
      </Row>
    </Fragment>
  );
}
