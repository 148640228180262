import { Col, Row } from 'antd';
import { Fragment } from 'react';
import ReactSpeedoMeter from '../../../components-shared/ReactSpeedoMeter';
import { GaugeListType } from '../../../@types';
import { getUTCFromDateTime } from '../../../utils/dayjs-helpers';
import { getTwoDecimalPoints } from '../../../shared/helpers';

type PropsType = {
  gauges: GaugeListType[];
  gaugeDateTime: string;
  loading: boolean;
};

const GaugeSection = ({ gauges, gaugeDateTime, loading }: PropsType) => {
  return (
    <Fragment>
      <Row className="gauge-section-header-gap" />

      <Row justify="center" align="middle" className="gauge-section-header">
        <Col xs={16} className="text-center">
          {gaugeDateTime && (
            <Fragment>
              {`Real-time data: as at ${getUTCFromDateTime({
                date: gaugeDateTime,
              })}`}
            </Fragment>
          )}
        </Col>
      </Row>

      <Row className="gauge-section-gap" />

      <Row className="gauges" justify="center">
        {gauges.length > 0
          ? gauges.map((el) => {
              const shortName = el.shortName.replace(
                /(\d+)/g,
                '<sub >$1</sub>'
              );
              const actualValue = getTwoDecimalPoints(el.actualValue);
              return (
                <Fragment key={el.shortName}>
                  <Col xl={4}>
                    <Row justify="center">
                      <Col dangerouslySetInnerHTML={{ __html: shortName }} />
                    </Row>
                    <Row justify="center">
                      <Col>
                        <ReactSpeedoMeter
                          {...el}
                          actualValue={actualValue}
                          speedoMeterProps={{ height: 100 }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Fragment>
              );
            })
          : gauges.length === 0 &&
            !loading && (
              <Col
                style={{
                  fontSize: '16px',
                  fontStyle: 'italic',
                  color: 'rgb(54, 114, 248)',
                }}>
                No recent data
              </Col>
            )}
      </Row>
    </Fragment>
  );
};

export default GaugeSection;
