import { ApiMethodType } from '../@types';
import { getOldApiPath } from './api-path';

const apiService = {
  api: `api`,
  prod: `prod`,
};

const apiMethod: ApiMethodType = {
  get: `GET`,
  post: `POST`,
  put: `PUT`,
  delete: `DELETE`,
  patch: `PATCH`,
  purge: `PURGE`,
};

// apiMethod.

const apiVersion = {
  v01: `V01`,
  v02: `V02`,
  v03: `V03`,
};

const contentType = {
  json: 'application/json',
  formUrlEncoded: 'application/x-www-form-urlencoded',
  multipartFormData: 'multipart/form-data',
};

class AuthApi {
  getLogin(params: {
    username: string;
    password: string;
    newpassword?: string;
  }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/login`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getTokenLogin() {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/tokenlogin`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
    };
  }

  getResetPassword(params: { username: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/passwordreset`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }
}

export const authApi = new AuthApi();

class LocationApi {
  getLocations(
    params?: {
      region?: string;
      centre?: string;
      radius?: string;
      groupID?: string;
      withSensors?: boolean;
    },
    queryParms?: {}
  ) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/locations`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getLocationDetails(params?: {}, queryParms?: { locationID: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/locations/${queryParms?.locationID}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getLocationLatest(params?: {}, queryParms?: { locationID: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/locations/${queryParms?.locationID}/latest.json`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params: {},
    };
  }

  getLocationAverages(
    params?: {
      lasthours?: string;
      lastdays?: string;
      lastmins?: string;
      from?: string;
      to?: string;
      split?: string;
    },
    queryParms?: { locationID: string }
  ) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/locations/${queryParms?.locationID}/tsd.json`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getLocationAveragesCSV(params?: {}, queryParms?: { locationID: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/locations/${queryParms?.locationID}/tsd.csv`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getDaqi(params?: {}, queryParms?: { locationID: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/locations/${queryParms?.locationID}/daqi.json`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }
}

export const locationApi = new LocationApi();

class UserApi {
  getUserDetails(params?: {}, queryParms?: { userID: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/users/${queryParms?.userID}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  putUserDetails(params?: {}, queryParms?: { userID: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/users/${queryParms?.userID}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.json,
      params,
    };
  }

  getApiKeys(params?: {}, queryParms?: { userID: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/users/${queryParms?.userID}/apikeys`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  postApiKeys(params?: {}, queryParms?: { userID: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/users/${queryParms?.userID}/apikeys`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.json,
      params,
    };
  }

  deleteApiKeys(params?: {}, queryParms?: { userID: string; keyID: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/users/${queryParms?.userID}/apikeys/${queryParms?.keyID}`;
    return {
      url,
      method: apiMethod.delete,
      contentType: contentType.json,
      params,
    };
  }
}

export const userApi = new UserApi();

class FloorPlanApi {
  getFloorPlans() {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/floorplans`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params: {},
    };
  }

  getFloorPlanLocations(params?: {}, queryParms?: { floorPlanID: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/floorplans/${queryParms?.floorPlanID}/locations`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params: {},
    };
  }

  getAsset(params?: {}, queryParms?: { assetID: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/assets/${queryParms?.assetID}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params: {},
    };
  }
}

export const floorPlanApi = new FloorPlanApi();

class DisplaysApi {
  getDisplays() {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/displays`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params: {},
    };
  }

  getDisplay(params?: {}, queryParms?: { name: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/displays/${queryParms?.name}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params: {},
    };
  }

  postDisplay() {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/displays`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.json,
      params: {},
    };
  }

  putDisplay(params?: {}, queryParms?: { name: string }) {
    const apiPath = getOldApiPath();
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/displays/${queryParms?.name}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.json,
      params: {},
    };
  }
}

export const displaysApi = new DisplaysApi();

class BuildingApi {
  getBuildings(params?: {}, queryParms?: {}) {
    const apiPath = getOldApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/buildings`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }
}

export const buildingApi = new BuildingApi();
