import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { rootReducer } from '../reducers';
import sagas from '../sagas';

class ReduxStore {
  private composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  });
  private initialiseSagaMiddleware = createSagaMiddleware();
  private globalStore = createStore(
    rootReducer,
    /* preloadedState, */ this.composeEnhancers(
      applyMiddleware(this.initialiseSagaMiddleware)
      // other store enhancers if any
    )
  );
  constructor() {
    this.initialiseSagaMiddleware.run(sagas);
  }

  get store() {
    return this.globalStore;
  }
}

const reduxStore = new ReduxStore();

export default reduxStore;
