import { Fragment, Component, CSSProperties } from 'react';
import { Row, Col, Popover, message } from 'antd';
import axios from 'axios';
import update from 'immutability-helper';
import { NumberSize, Resizable as ReResizable } from 're-resizable';
import { Line as LineChart } from 'react-chartjs-2';
import { HiOutlineCog } from 'react-icons/hi';
import { IoIosArrowDown } from 'react-icons/io';
import { FiSave } from 'react-icons/fi';
import Draggable from 'react-draggable';
import { Direction } from 're-resizable/lib/resizer';
import { Fab } from 'react-tiny-fab';

import {
  AxiosHttpAllSettledResponsesType,
  LocationListType,
  UserDataType,
} from '../../../@types';

import {
  CustomizableDivsType,
  resizableCursorTypes,
  CanvasPageStateType,
} from '../helpers';
import {
  httpCallAllSettled,
  httpCallErrorHandling,
} from '../../../api-services/api';
import { displaysApi, locationApi } from '../../../api-services/api-list';
import { manageChartData, manageGaugeData } from '../../../utils';
import AntdCard from '../../../components-shared/AntdCard';
import PopupContent from '../PopupContent';
import ReactSpeedoMeter from '../../../components-shared/ReactSpeedoMeter';
import { cloneDeep } from '../../../utils/lodash-libs';
import { handleNotification } from '../../../utils/notification-handler';

// import 'react-tiny-fab/dist/styles.css';
import { checkValidation } from '../../../utils/validation';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { customPageRoutes } from '../../../routes/routes-list';
import AntdCoverSpinner from '../../../components-shared/AntdCoverSpinner';
import 'react-tiny-fab/dist/styles.css';

// const time = 300000; // 5 minutes
// const time = 10000;

interface PropsType
  extends RouteComponentProps<
    {},
    {},
    { isEditable: boolean; pageName: string }
  > {
  userData: Partial<UserDataType>;
}

class CanvasPage extends Component<PropsType, CanvasPageStateType> {
  axiosCancelSource = axios.CancelToken.source();
  _isMounted = false;
  // interval: NodeJS.Timeout | undefined;
  intervals: { id: string; interval: NodeJS.Timeout }[] = [];

  mainButtonStyles: CSSProperties = {
    // backgroundColor: '#E74C3C',
    backgroundColor: '#1890ff',
    color: '#fff',
    fontSize: 24,
  };
  actionButtonStyles: CSSProperties = {
    backgroundColor: '#3498DB',
    // backgroundColor: '#1890ff',
  };

  constructor(props: PropsType) {
    super(props);

    this.state = {
      locationIds: [],
      customizableDivs: [],
      timeData: [],
      hasSavedData: false,
      loading: true,
      isEditable: props.location.state?.isEditable ?? false,
      pageName: props.location.state?.pageName ?? '',
    };
  }

  handleState = (data: Partial<CanvasPageStateType>, callback?: () => void) => {
    this._isMounted &&
      this.setState(
        (prev) => {
          return {
            ...prev,
            ...data,
          };
        },
        () => {
          callback?.();
        }
      );
  };

  componentDidMount() {
    this._isMounted = true;
    const { userData } = this.props;
    const { pageName } = this.state;
    const apiDetails = locationApi.getLocations();

    let customizableDivs: CustomizableDivsType[] = [];
    let timeData: { id: string; value: string }[] = [];

    if (userData.token) {
      const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
        if (responses.length > 0) {
          let stateData: Partial<CanvasPageStateType> = {};
          if (responses[0].status === 'fulfilled') {
            const temp: string[] = responses[0].value.data?.data?.map(
              (el: LocationListType) => el.locationID
            );
            stateData = update(stateData, {
              locationIds: { $set: temp },
              loading: { $set: false },
            });
          } else {
            this._isMounted && httpCallErrorHandling(responses[0]);
            stateData = update(stateData, {
              loading: { $set: false },
            });
          }
          if (responses[1].status === 'fulfilled') {
            customizableDivs = responses[1].value.data?.data?.elements;

            if (customizableDivs && customizableDivs.length > 0) {
              customizableDivs = customizableDivs.map((el) => {
                timeData.push({ id: el.id, value: el.configDetails.hours });
                return { ...el, isDraggable: false };
              });

              stateData = update(stateData, {
                customizableDivs: { $set: customizableDivs ?? [] },
                timeData: { $set: timeData },
                hasSavedData: { $set: true },
                loading: { $set: false },
              });
            }
          } else {
            if (responses[1].reason?.response?.status !== 404) {
              this._isMounted && httpCallErrorHandling(responses[1]);
            }

            stateData = update(stateData, {
              loading: { $set: false },
            });
          }

          if (Object.keys(stateData).length > 0) {
            this.handleState({ ...stateData }, () => {
              if (customizableDivs && customizableDivs.length > 0) {
                customizableDivs.forEach((el) => {
                  this.onSubmitClick(el.id, false);
                });
              }
            });
          }
        }
      };

      const getDisplay = displaysApi.getDisplay(undefined, { name: pageName });

      httpCallAllSettled({
        requestConfig: [{ ...apiDetails }, { ...getDisplay }],
        headersConfig: {
          token: userData.token,
          cancelToken: this.axiosCancelSource.token,
        },
        applyData: handleResponses,
      });
    } else {
      this.handleState({ loading: false });
    }

    // this.interval = setInterval(() => {
    //   const { customizableDivs } = this.state;
    //   if (customizableDivs.length > 0) {
    //     this.refreshData();
    //   }
    // }, time);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
    if (this.intervals.length > 0) {
      this.intervals.forEach((el) => {
        clearInterval(el.interval);
      });
    }
  }

  componentDidUpdate(prevProps: PropsType, prevState: CanvasPageStateType) {
    if (
      this.state.customizableDivs.length > prevState.customizableDivs.length
    ) {
      const elementsList = document.getElementsByClassName(
        'sensors'
      ) as HTMLCollectionOf<HTMLElement>;

      if (elementsList.length > 0) {
        for (let index = 0; index < elementsList.length; index++) {
          const element: any = elementsList[index];

          if (!element.mouseover) {
            const id = element?.classList?.[1];

            element.addEventListener(
              'mouseover',
              (e: any) => {
                const { customizableDivs } = this.state;
                const cursor = e.target?.style?.cursor;
                const matched = customizableDivs.find((el) => el.id === id);
                if (
                  resizableCursorTypes.includes(cursor) &&
                  matched?.isDraggable
                ) {
                  const temp = customizableDivs.map((el) => {
                    if (el.id === id) {
                      return { ...el, isDraggable: false };
                    }
                    return { ...el };
                  });

                  this.handleState({ customizableDivs: temp });
                } else if (
                  !matched?.isDraggable &&
                  (cursor === 'move' ||
                    (typeof cursor === 'string' &&
                      !resizableCursorTypes.includes(cursor)))
                ) {
                  const temp = customizableDivs.map((el) => {
                    if (el.id === id) {
                      return { ...el, isDraggable: true };
                    }
                    return { ...el };
                  });

                  this.handleState({ customizableDivs: temp });
                }
              },
              false
            );
          }
        }
      }
    }
  }

  onSaveData = async () => {
    const { customizableDivs, hasSavedData, pageName } = this.state;
    const { userData } = this.props;

    if (customizableDivs.length > 0) {
      const temp = cloneDeep(customizableDivs).map((el) => {
        const chartData: any = el.chartData;
        if (chartData._meta) {
          const { _meta, ...restChartData } = chartData;

          el = update(el, {
            chartData: { $set: restChartData },
          });
        }
        return { ...el };
      });

      try {
        const apiDetailsPost = displaysApi.postDisplay();
        const apiDetailsPut = displaysApi.putDisplay(undefined, {
          name: pageName,
        });

        if (userData.token) {
          const handleResponses = (
            responses: AxiosHttpAllSettledResponsesType
          ) => {
            if (responses.length > 0) {
              if (responses[0].status === 'fulfilled') {
                const result = responses[0].value?.data;
                this._isMounted &&
                  handleNotification('success', { message: result?.message });
              } else {
                this._isMounted && httpCallErrorHandling(responses[0]);
              }
            }
          };
          httpCallAllSettled({
            requestConfig: hasSavedData
              ? [{ ...apiDetailsPut, data: { name: pageName, elements: temp } }]
              : [
                  {
                    ...apiDetailsPost,
                    data: { name: pageName, elements: temp },
                  },
                ],
            headersConfig: {
              token: userData.token,
              cancelToken: this.axiosCancelSource.token,
            },
            applyData: handleResponses,
          });
        }
      } catch (error: any) {
        message.error(error?.response?.data?.message ?? 'Error!');
      }
    } else {
      message.error('Please configure some div first');
    }
  };

  onDragStop = (id: string, data: any) => {
    const { customizableDivs } = this.state;

    let temp = [...customizableDivs];

    temp = temp.map((el) => {
      let obj = { ...el };

      if (obj.id === id) {
        obj = update(obj, {
          dragPosition: {
            $set: { x: data?.x, y: data?.y },
          },
        });
      }
      return { ...obj };
    });

    this.handleState({ customizableDivs: temp });
  };

  onCreateDiv = () => {
    const { customizableDivs } = this.state;

    const tempObj = {
      // id: shortUuid().new(),
      id: `id_${customizableDivs.length + 1}`,
      isDraggable: true,
      configDetails: {
        locationId: '',
        displayType: '',
        sensor: '',
        hours: '',
        color: '',
        refreshInterval: '',
        freeText: '',
        textSize: '',
      },
      dragPosition: undefined,
      dimensions: undefined,
      isConfigVisible: false,
      isColorVisible: false,
      isLoading: false,
      chartData: {},
      gaugeData: {},
    };

    const temp = update(customizableDivs, {
      $push: [tempObj],
    });

    this.handleState({ customizableDivs: temp });
  };

  handleVisibility = (visible: boolean, id: string) => {
    const { customizableDivs } = this.state;

    let temp = [...customizableDivs];
    temp = temp.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          isConfigVisible: visible,
        };
      }
      return { ...el };
    });

    this.handleState({ customizableDivs: temp });
  };

  handleInputChange = (value: string, name: string, id: string) => {
    const { customizableDivs, timeData } = this.state;

    let temp = [];
    if (name === 'hours') {
      temp = [...timeData];
      if (temp.length === 0) {
        temp.push({ id, value });
      } else {
        const matched = temp.find((el) => el.id === id);
        if (matched) {
          temp = temp.map((el) => {
            if (el.id === id) {
              return { ...el, value };
            }
            return { ...el };
          });
        } else {
          temp.push({ id, value });
        }
      }

      this.handleState({ timeData: temp });
    } else {
      temp = [...customizableDivs];
      if (name === 'displayType') {
        temp = temp.map((el) => {
          if (el.id === id) {
            return {
              ...el,
              configDetails: {
                ...el.configDetails,
                locationId: '',
                sensor: '',
                hours: '',
                color: '',
                refreshInterval: '',
                freeText: '',
              },
            };
          }
          return { ...el };
        });
      }

      temp = temp.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            configDetails: { ...el.configDetails, [name]: value },
          };
        }
        return { ...el };
      });

      if (
        name === 'textSize' &&
        value &&
        !checkValidation(value, { isFloat: true })
      ) {
        return message.error('Please enter valid value!');
      }

      if (name === 'refreshInterval') {
        if (value === '0') {
          return message.error(`Refresh Interval can't be 0!`);
        } else if (value && !checkValidation(value, { isNumeric: true })) {
          return message.error('Please enter valid value!');
        }
      }

      this.handleState({ customizableDivs: temp });
    }
  };

  onDisableMove = (id: string, visible: boolean) => {
    const { customizableDivs } = this.state;

    let temp = [...customizableDivs];

    temp = temp.map((el) => {
      let tempObj = { ...el };
      if (tempObj.id === id) {
        tempObj = update(tempObj, {
          isDraggable: { $set: !visible },
          isColorVisible: { $set: visible },
        });
      }
      return { ...tempObj };
    });

    this.handleState({ customizableDivs: temp });
  };

  onSubmitClick = (id: string, isLoading?: boolean) => {
    const { customizableDivs, timeData } = this.state;
    const { userData } = this.props;

    const matched = customizableDivs.find((el) => el.id === id);
    const matchedTime = timeData.find((el) => el.id === id);

    if (matched && matched.configDetails.displayType === 'freeText') {
      let temp = [...customizableDivs];

      temp = temp.map((el) => {
        let obj = { ...el };
        if (obj.id === id) {
          obj = update(obj, {
            isConfigVisible: { $set: false },
            isLoading: { $set: false },
          });
        }
        return { ...obj };
      });

      this.handleState({ customizableDivs: temp });
    } else {
      const fethcData = () => {
        if (matched && matched.configDetails) {
          let key: keyof typeof matched.configDetails;
          for (key in matched.configDetails) {
            if (
              matched.configDetails['displayType'] === 'gauge' &&
              key !== 'color' &&
              key !== 'hours' &&
              key !== 'textSize' &&
              key !== 'freeText' &&
              key !== 'refreshInterval' &&
              !matched.configDetails[key]
            ) {
              return message.error('Please fill all the fields!');
            } else if (
              matched.configDetails['displayType'] === 'chart' &&
              key !== 'hours' &&
              key !== 'textSize' &&
              key !== 'freeText' &&
              key !== 'refreshInterval' &&
              !matched.configDetails[key]
            ) {
              return message.error('Please fill all the fields!');
            } else if (
              matched.configDetails['displayType'] === 'freeText' &&
              !matched.configDetails['freeText']
            ) {
              return message.error('Please fill all the fields!');
            } else if (matched.configDetails['displayType'] === '') {
              return message.error('Please fill all the fields!');
            }
          }

          let temp = [...customizableDivs];

          temp = temp.map((el) => {
            let obj = { ...el };
            if (obj.id === id) {
              obj = update(obj, {
                isConfigVisible: { $set: false },
                isLoading: { $set: isLoading !== undefined ? isLoading : true },
                configDetails: {
                  hours: {
                    $set: matchedTime?.value ?? obj.configDetails.hours,
                  },
                },
              });
            }
            return { ...obj };
          });

          this.handleState({ customizableDivs: temp });

          const getLocationDetails = locationApi.getLocationDetails(undefined, {
            locationID: matched.configDetails.locationId,
          });
          let locationData: Partial<LocationListType> = {};

          if (userData.token) {
            if (matched?.configDetails?.displayType === 'chart') {
              const getLocationAverages = locationApi.getLocationAverages(
                { lasthours: matchedTime?.value },
                {
                  locationID: matched.configDetails.locationId,
                }
              );

              const handleResponses = (
                responses: AxiosHttpAllSettledResponsesType
              ) => {
                if (responses.length > 0) {
                  let timeSeriesData: any;
                  if (responses[0].status === 'fulfilled') {
                    locationData = responses?.[0].value.data?.data;
                  } else {
                    this._isMounted && httpCallErrorHandling(responses[0]);
                    temp = temp.map((el) => {
                      let obj = { ...el };
                      if (obj.id === id) {
                        obj = update(obj, {
                          isLoading: { $set: false },
                        });
                      }
                      return { ...obj };
                    });
                  }

                  if (responses[1].status === 'fulfilled') {
                    timeSeriesData =
                      responses?.[1].value.data?.data?.timeSeriesData;
                  } else {
                    this._isMounted && httpCallErrorHandling(responses[1]);
                    temp = temp.map((el) => {
                      let obj = { ...el };
                      if (obj.id === id) {
                        obj = update(obj, {
                          isLoading: { $set: false },
                        });
                      }
                      return { ...obj };
                    });
                  }

                  if (timeSeriesData && locationData) {
                    const tempChartData = manageChartData({
                      phenomList: locationData?.sensorSpecs || [],
                      locationID: locationData?.locationID || '',
                      locationAveragesList: timeSeriesData,
                      chartColor: matched?.configDetails?.color,
                    });

                    if (tempChartData) {
                      const tempChartMatch = tempChartData.find((element) => {
                        return (
                          element?.shortName?.toUpperCase() ===
                          matched?.configDetails?.sensor?.toUpperCase()
                        );
                      });

                      temp = temp.map((el) => {
                        let obj = { ...el };

                        if (obj.id === id) {
                          obj = update(obj, {
                            chartData: { $set: tempChartMatch ?? {} },
                            gaugeData: { $set: {} },
                            isConfigVisible: { $set: false },
                            isLoading: { $set: false },
                          });
                        }
                        return { ...obj };
                      });

                      this.handleState({ customizableDivs: temp });
                    }
                  } else {
                    temp = temp.map((el) => {
                      let obj = { ...el };
                      if (obj.id === id) {
                        obj = update(obj, {
                          isConfigVisible: { $set: false },
                          isLoading: { $set: false },
                        });
                      }
                      return { ...obj };
                    });

                    this.handleState({ customizableDivs: temp });
                  }
                }
              };
              httpCallAllSettled({
                requestConfig: [
                  { ...getLocationDetails },
                  { ...getLocationAverages },
                ],
                headersConfig: {
                  token: userData.token,
                  // cancelToken: this.axiosCancelSource.token,
                },
                applyData: handleResponses,
              });
            } else {
              const getLocationLatest = locationApi.getLocationLatest(
                undefined,
                {
                  locationID: matched.configDetails.locationId,
                }
              );
              let latestData: any;
              const handleResponses = (
                responses: AxiosHttpAllSettledResponsesType
              ) => {
                if (responses.length > 0) {
                  if (responses[0].status === 'fulfilled') {
                    locationData = responses?.[0].value.data?.data;
                  } else {
                    this._isMounted && httpCallErrorHandling(responses[0]);
                    temp = temp.map((el) => {
                      let obj = { ...el };
                      if (obj.id === id) {
                        obj = update(obj, {
                          isLoading: { $set: false },
                        });
                      }
                      return { ...obj };
                    });
                  }

                  if (responses[1].status === 'fulfilled') {
                    latestData = responses?.[1].value.data?.data?.latestData;
                  } else {
                    this._isMounted && httpCallErrorHandling(responses[1]);
                    temp = temp.map((el) => {
                      let obj = { ...el };
                      if (obj.id === id) {
                        obj = update(obj, {
                          isLoading: { $set: false },
                        });
                      }
                      return { ...obj };
                    });
                  }

                  if (latestData && locationData) {
                    const tempGaugeData = manageGaugeData({
                      gaugeColor: matched?.configDetails?.color,
                      phenomList: locationData?.sensorSpecs ?? [],
                      locationID: locationData?.locationID ?? '',
                      gaugeSensors: latestData?.Dnum,
                    });

                    if (tempGaugeData) {
                      const tempGaugeMatch = tempGaugeData.find((element) => {
                        return (
                          element?.shortName?.toUpperCase() ===
                          matched?.configDetails?.sensor?.toUpperCase()
                        );
                      });

                      temp = temp.map((el) => {
                        let obj = { ...el };

                        if (obj.id === id) {
                          obj = update(obj, {
                            chartData: { $set: {} },
                            gaugeData: { $set: tempGaugeMatch ?? {} },
                            isConfigVisible: { $set: false },
                            isLoading: { $set: false },
                          });
                        }
                        return { ...obj };
                      });

                      this.handleState({ customizableDivs: temp });
                    }
                  }
                }
              };
              httpCallAllSettled({
                requestConfig: [
                  { ...getLocationDetails },
                  { ...getLocationLatest },
                ],
                headersConfig: {
                  token: userData.token,
                  // cancelToken: this.axiosCancelSource.token,
                },
                applyData: handleResponses,
              });
            }
          }
        }
      };

      fethcData();

      if (matched && matched.configDetails?.refreshInterval) {
        const val = setInterval(() => {
          fethcData();
        }, parseInt(matched.configDetails.refreshInterval) * 60000);

        if (this.intervals.length > 0) {
          const matchedInterval = this.intervals.find((el) => el.id === id);
          if (!matchedInterval) {
            this.intervals = update(this.intervals, {
              $push: [{ id, interval: val }],
            });
          } else {
            this.intervals = this.intervals.map((el) => {
              if (el.id === id) {
                clearInterval(el.interval);
                el = update(el, {
                  interval: { $set: val },
                });
              }
              return { ...el };
            });
          }
        } else {
          this.intervals = update(this.intervals, {
            $push: [{ id, interval: val }],
          });
        }
      }
    }
  };

  onDeleteClick = (id: string) => {
    const { customizableDivs, timeData } = this.state;
    let temp = [...customizableDivs];
    temp = temp.filter((el) => el.id !== id);

    let tempTime = [...timeData];
    tempTime = tempTime.filter((el) => el.id !== id);

    this.handleState({ customizableDivs: temp, timeData: tempTime });
  };

  onResizeStop = (
    event: MouseEvent | TouchEvent,
    direction: Direction,
    refToElement: HTMLElement,
    delta: NumberSize,
    id: string
  ) => {
    const { customizableDivs } = this.state;
    let temp = [...customizableDivs];
    if (id) {
      const domElement = document?.querySelector?.(`.${id}`);
      if (domElement?.clientHeight && domElement?.clientWidth) {
        temp = temp.map((el) => {
          let obj = { ...el };

          if (el.id === id) {
            obj = update(obj, {
              dimensions: {
                $set: {
                  width: domElement.clientWidth,
                  height: domElement.clientHeight,
                },
              },
            });
          }
          return { ...obj };
        });
      }

      this.handleState({ customizableDivs: temp });
    }
  };

  // refreshData = async () => {
  //   const { userData } = this.props;
  //   const { customizableDivs } = this.state;
  //   const chartApis: (RequestConfigType & {
  //     locationId: string;
  //     uid: string;
  //   })[] = [];
  //   const gaugeApis: (RequestConfigType & {
  //     locationId: string;
  //     uid: string;
  //   })[] = [];
  //   const locationDetailsApis: RequestConfigType[] = [];
  //   let locationDetailsList: LocationListType[] = [];

  //   let temp = [...customizableDivs];

  //   if (userData.token) {
  //     temp = temp.map((el) => {
  //       let tempObj = { ...el };

  //       if (tempObj.configDetails.displayType === 'chart') {
  //         tempObj = update(tempObj, { isLoading: { $set: true } });
  //         const getLocationAverages = locationApi.getLocationAverages(
  //           { lasthours: tempObj.configDetails.hours },
  //           {
  //             locationID: tempObj.configDetails.locationId,
  //           }
  //         );
  //         chartApis.push({
  //           ...getLocationAverages,
  //           locationId: tempObj.configDetails.locationId,
  //           uid: tempObj.id,
  //         });
  //       } else if (tempObj.configDetails.displayType === 'gauge') {
  //         tempObj = update(tempObj, { isLoading: { $set: true } });
  //         const getLocationLatest = locationApi.getLocationLatest(undefined, {
  //           locationID: tempObj.configDetails.locationId,
  //         });
  //         gaugeApis.push({
  //           ...getLocationLatest,
  //           locationId: tempObj.configDetails.locationId,
  //           uid: tempObj.id,
  //         });
  //       }
  //       return { ...tempObj };
  //     });

  //     let idList = temp.map((el) => {
  //       return el.configDetails.locationId;
  //     });
  //     idList = Array.from(new Set(idList));

  //     idList.forEach((el) => {
  //       const getLocationDetails = locationApi.getLocationDetails(undefined, {
  //         locationID: el,
  //       });
  //       locationDetailsApis.push(getLocationDetails);
  //     });

  //     this.handleState({ customizableDivs: temp });

  //     if (locationDetailsApis.length > 0) {
  //       try {
  //         const detailsAxios = locationDetailsApis.map((el) => {
  //           return axios({
  //             ...el,
  //             headers: {
  //               'X-API-KEY': userData.token,
  //               'Content-Type': el.contentType || 'application/json',
  //             },
  //             cancelToken: this.axiosCancelSource.token,
  //           });
  //         });

  //         if (detailsAxios.length > 0) {
  //           const locationDetailsResponses = await Promise.all(detailsAxios);

  //           if (locationDetailsResponses.length > 0) {
  //             locationDetailsResponses.forEach((el) => {
  //               if (el?.data?.data) {
  //                 locationDetailsList = update(locationDetailsList, {
  //                   $push: [el.data.data],
  //                 });
  //               }
  //             });
  //           }

  //           if (locationDetailsList.length > 0) {
  //             const chartAxios = chartApis.map((el) => {
  //               return axios({
  //                 ...el,
  //                 headers: {
  //                   'X-API-KEY': userData.token,
  //                   'Content-Type': el.contentType || 'application/json',
  //                 },
  //                 cancelToken: this.axiosCancelSource.token,
  //               });
  //             });

  //             if (chartAxios.length > 0) {
  //               const chartResponses: any[] = await Promise.all(chartAxios);
  //               if (chartResponses.length > 0) {
  //                 chartResponses.forEach((elem) => {
  //                   // const locationData = locationDetailsList.find(
  //                   //   (item) =>
  //                   //     item.locationID === elem?.data?.data?.locationID
  //                   // );
  //                   const locationData = locationDetailsList.find(
  //                     (item) => item.locationID === elem?.config?.locationId
  //                   );
  //                   const timeSeriesData = elem.data?.data?.timeSeriesData;

  //                   if (timeSeriesData) {
  //                     const matchedDiv = temp.find(
  //                       (item) => item.id === elem?.config?.uid
  //                     );
  //                     const tempChartData = manageChartData({
  //                       phenomList: locationData?.sensorSpecs || [],
  //                       locationID: locationData?.locationID || '',
  //                       locationAveragesList: timeSeriesData,
  //                       chartColor: matchedDiv?.configDetails?.color,
  //                     });

  //                     if (tempChartData) {
  //                       const tempChartMatch = tempChartData.find((element) => {
  //                         return (
  //                           element?.shortName?.toUpperCase() ===
  //                           matchedDiv?.configDetails?.sensor?.toUpperCase()
  //                         );
  //                       });

  //                       temp = temp.map((el) => {
  //                         let obj = { ...el };

  //                         if (obj.id === elem?.config?.uid) {
  //                           obj = update(obj, {
  //                             chartData: { $set: tempChartMatch ?? {} },
  //                             isConfigVisible: { $set: false },
  //                             isLoading: { $set: false },
  //                           });
  //                         }
  //                         return { ...obj };
  //                       });
  //                     }
  //                   }
  //                 });
  //               }
  //             }

  //             const gaugeAxios = gaugeApis.map((el) => {
  //               return axios({
  //                 ...el,
  //                 headers: {
  //                   'X-API-KEY': userData.token,
  //                   'Content-Type': el.contentType || 'application/json',
  //                 },
  //                 cancelToken: this.axiosCancelSource.token,
  //               });
  //             });

  //             if (gaugeAxios.length > 0) {
  //               const gaugeResponses: any[] = await Promise.all(gaugeAxios);
  //               if (gaugeResponses.length > 0) {
  //                 gaugeResponses.forEach((elem) => {
  //                   const locationData = locationDetailsList.find(
  //                     (item) => item.locationID === elem?.config?.locationId
  //                   );
  //                   const latestData = elem?.data?.data?.latestData;

  //                   if (latestData) {
  //                     const matchedDiv = temp.find(
  //                       (item) => item.id === elem?.config?.uid
  //                     );
  //                     const tempGaugeData = manageGaugeData({
  //                       gaugeColor: matchedDiv?.configDetails?.color,
  //                       phenomList: locationData?.sensorSpecs ?? [],
  //                       locationID: locationData?.locationID ?? '',
  //                       gaugeSensors: latestData?.Dnum,
  //                     });

  //                     if (tempGaugeData) {
  //                       const tempGaugeMatch = tempGaugeData.find((element) => {
  //                         return (
  //                           element?.shortName?.toUpperCase() ===
  //                           matchedDiv?.configDetails?.sensor?.toUpperCase()
  //                         );
  //                       });

  //                       temp = temp.map((el) => {
  //                         let obj = { ...el };

  //                         if (obj.id === elem?.config?.uid) {
  //                           obj = update(obj, {
  //                             gaugeData: { $set: tempGaugeMatch ?? {} },
  //                             isConfigVisible: { $set: false },
  //                             isLoading: { $set: false },
  //                           });
  //                         }
  //                         return { ...obj };
  //                       });
  //                     }
  //                   }
  //                 });
  //               }
  //             }
  //           }
  //         }
  //       } catch (error) {
  //         handleNotification(
  //           'error',
  //           {
  //             message: error.response?.data?.message ?? error?.message,
  //           },
  //           { duration: 50000 }
  //         );
  //       } finally {
  //         temp = temp.map((el) => {
  //           let obj = { ...el };
  //           obj = update(obj, {
  //             isConfigVisible: { $set: false },
  //             isLoading: { $set: false },
  //           });
  //           return { ...obj };
  //         });

  //         this.handleState({ customizableDivs: temp });
  //       }
  //     }
  //   }
  // };

  render() {
    const {
      customizableDivs,
      locationIds,
      timeData,
      loading,
      isEditable,
      pageName,
    } = this.state;

    if (!pageName) {
      return <Redirect to={customPageRoutes.root()} />;
    }

    return (
      <Fragment>
        <AntdCoverSpinner active={loading}>
          <Row className="p-3">
            <Col xs={24} style={{ background: '#fff', minHeight: '100vh' }}>
              {customizableDivs.length > 0 &&
                customizableDivs.map((el, idx) => {
                  const matchTime = timeData.find((elem) => elem.id === el.id);
                  let isDrag = !el.isDraggable;
                  if (!isEditable) {
                    isDrag = true;
                  }
                  return (
                    <Fragment key={el.id}>
                      <Draggable
                        disabled={isDrag}
                        defaultClassName={`sensors ${el.id}`}
                        onStop={(e, data) => this.onDragStop(el.id, data)}
                        position={el.dragPosition}>
                        <ReResizable
                          style={{
                            border: isEditable ? '1px solid blue' : undefined,
                            textAlign: 'center',
                            cursor: isEditable ? 'move' : 'default',
                            position: 'absolute',
                          }}
                          defaultSize={{
                            width: 320,
                            height: 200,
                          }}
                          size={{
                            width: el.dimensions?.width ?? 320,
                            height: el.dimensions?.height ?? 200,
                          }}
                          onResizeStop={(
                            event,
                            direction,
                            refToElement,
                            delta
                          ) =>
                            this.onResizeStop(
                              event,
                              direction,
                              refToElement,
                              delta,
                              el.id
                            )
                          }
                          enable={{
                            top: false,
                            right: false,
                            bottom: false,
                            left: false,
                            topRight: false,
                            bottomRight: true,
                            bottomLeft: false,
                            topLeft: false,
                          }}>
                          <AntdCard
                            loading={el.isLoading}
                            style={{
                              height: '100%',
                              borderColor: !isEditable
                                ? 'transparent'
                                : undefined,
                            }}
                            bodyStyle={{
                              height: '100%',
                              padding: 12,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                            {isEditable && (
                              <Popover
                                overlayStyle={{ width: 300 }}
                                content={
                                  <Fragment>
                                    <PopupContent
                                      {...el.configDetails}
                                      hours={
                                        matchTime?.value ??
                                        el?.configDetails?.hours ??
                                        ''
                                      }
                                      isColorVisible={el.isColorVisible}
                                      isConfigVisible={el.isConfigVisible}
                                      locations={locationIds}
                                      id={el.id}
                                      handleInputChange={this.handleInputChange}
                                      onDisableMove={this.onDisableMove}
                                      onSubmitClick={this.onSubmitClick}
                                      onDeleteClick={this.onDeleteClick}
                                    />
                                  </Fragment>
                                }
                                // placement="bottom"
                                trigger="click"
                                visible={el.isConfigVisible}
                                onVisibleChange={(visible) =>
                                  this.handleVisibility(visible, el.id)
                                }
                                placement="bottom">
                                <HiOutlineCog
                                  size="1.5em"
                                  style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: 5,
                                    left: 5,
                                  }}
                                />
                              </Popover>
                            )}

                            {el?.configDetails?.displayType === 'chart' && (
                              <Fragment>
                                {el.chartData &&
                                Object.keys(el.chartData).length > 0 ? (
                                  <Fragment>
                                    <label
                                      style={{
                                        position: 'absolute',
                                        top: 5,
                                        fontSize: 14,
                                      }}>
                                      <strong>{`${el?.chartData?.locationID}`}</strong>
                                    </label>
                                    <LineChart
                                      data={{ datasets: [el.chartData] }}
                                      options={{
                                        scales: {
                                          xAxes: [
                                            {
                                              type: 'time',
                                              time: {
                                                unit:
                                                  Number(
                                                    el.configDetails.hours
                                                  ) > 24
                                                    ? 'day'
                                                    : 'hour',
                                              },
                                            },
                                          ],
                                        },
                                        responsive: true,
                                      }}
                                    />
                                  </Fragment>
                                ) : (
                                  <Fragment>No Data Found!</Fragment>
                                )}
                              </Fragment>
                            )}

                            {el?.configDetails?.displayType === 'gauge' && (
                              <Fragment>
                                {el.gaugeData &&
                                Object.keys(el.gaugeData).length > 0 ? (
                                  <Fragment>
                                    <label
                                      style={{
                                        position: 'absolute',
                                        top: 5,
                                        fontSize: 14,
                                      }}>
                                      <strong>{`${el?.gaugeData?.locationID}`}</strong>
                                    </label>
                                    <label
                                      style={{
                                        position: 'absolute',
                                        top: 30,
                                        fontSize: 12,
                                      }}>
                                      <strong>{`${el?.gaugeData?.longName}(${el?.gaugeData?.shortName})`}</strong>
                                    </label>
                                    <Row
                                      justify="center"
                                      align="middle"
                                      style={{ marginTop: 36 }}>
                                      <Col>
                                        <ReactSpeedoMeter
                                          {...el.gaugeData}
                                          speedoMeterProps={{
                                            width: el?.dimensions?.width
                                              ? el?.dimensions?.width -
                                                (el?.dimensions?.width * 20) /
                                                  100
                                              : 320 - (320 * 20) / 100,
                                            height: el?.dimensions?.height
                                              ? el?.dimensions?.height -
                                                (el?.dimensions?.height * 20) /
                                                  100
                                              : 200 - (200 * 20) / 100,
                                            textColor:
                                              el.gaugeData?.graphColour,
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </Fragment>
                                ) : (
                                  <Fragment>No Data Found!</Fragment>
                                )}
                              </Fragment>
                            )}

                            {el?.configDetails?.displayType === 'freeText' && (
                              <Row
                                justify="center"
                                align="middle"
                                style={{
                                  color: el.configDetails.color,
                                  fontSize: el.configDetails.textSize
                                    ? parseFloat(el.configDetails.textSize)
                                    : 14,
                                }}>
                                <Col>{el.configDetails.freeText}</Col>
                              </Row>
                            )}
                          </AntdCard>
                          {isEditable && (
                            <IoIosArrowDown
                              style={{
                                position: 'absolute',
                                marginLeft: 'auto',
                                bottom: 0,
                                right: 0,
                                transform: `rotate(-45deg)`,
                              }}
                              size="1.2em"
                            />
                          )}
                        </ReResizable>
                      </Draggable>
                    </Fragment>
                  );
                })}
            </Col>
          </Row>

          {isEditable && (
            <Fragment>
              <Fab
                mainButtonStyles={{
                  backgroundColor: '#1890ff',
                  color: '#fff',
                  fontSize: 24,
                }}
                // style={position}
                icon={'+'}
                event={'click'}
                alwaysShowTitle={false}
                onClick={this.onCreateDiv}></Fab>

              <Fab
                mainButtonStyles={{
                  backgroundColor: '#1890ff',
                  color: '#fff',
                  fontSize: 24,
                }}
                style={{ bottom: 88, right: 24 }}
                icon={<FiSave />}
                event={'click'}
                alwaysShowTitle={false}
                onClick={this.onSaveData}></Fab>
            </Fragment>
          )}

          {/* {isEditable && (
            <Fab
              mainButtonStyles={this.mainButtonStyles}
              // style={position}
              icon={'+'}
              event={'click'}
              alwaysShowTitle={false}>
              <Action
                text="Create Div"
                style={this.actionButtonStyles}
                onClick={this.onCreateDiv}>
                <IoMdCreate />
              </Action>
              <Action
                text="Save"
                style={this.actionButtonStyles}
                onClick={this.onSaveData}>
                <FiSave />
              </Action>
            </Fab>
          )} */}
        </AntdCoverSpinner>
      </Fragment>
    );
  }
}

export default CanvasPage;
