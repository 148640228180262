import {
  Fragment,
  useReducer,
  Dispatch,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { MapContainer, ImageOverlay, GeoJSON } from 'react-leaflet';
import Leaflet, { Map } from 'leaflet';
import { Radio, RadioChangeEvent } from 'antd';
import {
  FloorPlanMapDetailsType,
  MapBoundsType,
  MapCoordinatesType,
} from '@airsensa/react-components/dist/@types';

import 'leaflet.heat';

import {
  GeoJSONFeaturesType,
  MarkersListType,
  ReducerHookActionType,
  ReduxStoreType,
  BuildingFloorPlanType,
  FloorPlanLocationListType,
} from '../../../@types';
import CircleMapMarker from '../../../components-shared/LeafletMarker/CircleMapMarker';
import { FloorPlanContext, getFloorPlanFromBuildings } from '../helpers';
import { useSelector } from 'react-redux';
import {
  getHighestValArrObj,
  ReducerActionTypes,
  updateImmutably,
  // customCRSSimple,
  polygonDefaultColor,
  RegionColorSelection,
  getLowestValArrObj,
  ColorCodeDetails,
  getMapBoundsAndCenter,
  CRS_CUSTOM_SCALE,
} from '../../../shared/helpers';

import cssStyles from '../styles/floorPlans.module.scss';

interface StateType {
  mapCenter: MapCoordinatesType;
  floorPlanLocations: FloorPlanLocationListType[];
  mapBounds?: MapBoundsType;
  mapInstance?: Map;
  selectedBuildingFloorPlan: Partial<BuildingFloorPlanType>;
  regionColorType: RegionColorSelection;
}

interface PropsType {
  mapDetails: FloorPlanMapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  selectedLocationID: string;
  onMarkerClick?: (locationID: string) => void;
}

const initState: StateType = {
  mapCenter: [0, 0],
  floorPlanLocations: [],
  selectedBuildingFloorPlan: {},
  regionColorType: RegionColorSelection.Highest,
};

const PolygonMap = ({
  mapDetails,
  floorPlanLocationList,
  selectedLocationID,
  onMarkerClick,
}: PropsType) => {
  let bounds: MapBoundsType | undefined;
  let center: MapCoordinatesType | undefined;
  if (mapDetails.height && mapDetails.width) {
    const result = getMapBoundsAndCenter({
      height: Number(mapDetails.height),
      width: Number(mapDetails.width),
    });
    bounds = result.bounds;
    center = result.centre;
  }

  const [state, dispatchToState]: [
    state: StateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(reducer, {
    ...initState,
    mapBounds: bounds,
    mapCenter: center ?? [0, 0],
  });

  const ctx = useContext(FloorPlanContext);
  const floorPlanIDStore = useSelector(
    (store: ReduxStoreType) => store.location.floorPlanDetails.floorPlanID
  );

  const {
    selectedBuildingFloorPlan,
    mapCenter,
    mapBounds,
    regionColorType,
    mapInstance,
  } = state;

  const getSelectedPhenomDetails = useCallback(
    (
      id: string
    ): {
      sensorName: string;
      sensorValue: number | null;
      markers: MarkersListType[];
    } | null => {
      if (ctx.selectedPhenoms.length === 1) {
        const name = ctx.selectedPhenoms[0];
        const matchedLoc = floorPlanLocationList.find((loc) => {
          return loc.locationID === id;
        });

        const matchedSensor = matchedLoc?.sensorSpecs?.find(
          (s) => s.shortName.toUpperCase() === name.toUpperCase()
        );

        if (matchedLoc && matchedSensor) {
          const { latestData } = matchedLoc;
          const { markers } = matchedSensor;

          return {
            sensorName: name,
            markers,
            sensorValue: latestData.Dnum?.[name] ?? null,
          };
        }
      }
      return null;
    },
    [ctx.selectedPhenoms, floorPlanLocationList]
  );

  const onRegionSwitch = useCallback(
    (colorType: RegionColorSelection) => {
      if (
        ctx.buildingList.length > 0 &&
        floorPlanLocationList.length > 0 &&
        ctx.selectedPhenoms.length === 1
      ) {
        const selectedPhenom = ctx.selectedPhenoms[0];
        const selectedBuildingFloor = getFloorPlanFromBuildings(
          floorPlanIDStore,
          ctx.buildingList
        );
        let tempSelectedBuildingFoorPlan = { ...selectedBuildingFloor };
        if (
          selectedBuildingFloor &&
          selectedBuildingFloor.regions.outerPolygon.features &&
          selectedBuildingFloor.regions.outerPolygon.features.length > 0
        ) {
          const tempOuterRegion = getPolygonRegion({
            selectedBuildingFloor,
            floorPlanLocationList,
            selectedPhenom,
            regionsType: 'outerPolygon',
            colorType,
          });
          tempSelectedBuildingFoorPlan = updateImmutably(
            selectedBuildingFloor,
            {
              regions: {
                outerPolygon: {
                  features: {
                    $set: tempOuterRegion,
                  },
                },
              },
            }
          );
        }

        if (
          selectedBuildingFloor &&
          selectedBuildingFloor.regions.innerPolygons.features &&
          selectedBuildingFloor.regions.innerPolygons.features.length > 0
        ) {
          const tempInnerRegion = getPolygonRegion({
            selectedBuildingFloor,
            floorPlanLocationList,
            selectedPhenom,
            regionsType: 'innerPolygons',
            colorType,
          });

          tempSelectedBuildingFoorPlan = updateImmutably(
            selectedBuildingFloor,
            {
              regions: {
                innerPolygons: {
                  features: {
                    $set: tempInnerRegion,
                  },
                },
              },
            }
          );
        }

        dispatchToState({
          type: ReducerActionTypes.SetState,
          payload: {
            selectedBuildingFloorPlan: tempSelectedBuildingFoorPlan,
            regionColorType: colorType,
          },
        });
      } else if (
        floorPlanLocationList.length > 0 &&
        (ctx.selectedPhenoms.length === 0 || ctx.selectedPhenoms.length > 1)
      ) {
        const selectedBuildingFloor = getFloorPlanFromBuildings(
          floorPlanIDStore,
          ctx.buildingList
        );
        let tempSelectedBuildingFoorPlan = { ...selectedBuildingFloor };
        if (
          tempSelectedBuildingFoorPlan?.regions?.outerPolygon?.features &&
          tempSelectedBuildingFoorPlan.regions.outerPolygon.features.length > 0
        ) {
          const outer =
            tempSelectedBuildingFoorPlan.regions.outerPolygon.features.map(
              (el) => {
                return { ...el, colorCode: polygonDefaultColor };
              }
            );

          if (outer) {
            tempSelectedBuildingFoorPlan = updateImmutably(
              tempSelectedBuildingFoorPlan,
              {
                regions: {
                  outerPolygon: {
                    features: {
                      $set: outer,
                    },
                  },
                },
              }
            );
          }
        }

        if (
          tempSelectedBuildingFoorPlan?.regions?.innerPolygons?.features &&
          tempSelectedBuildingFoorPlan.regions.innerPolygons.features.length > 0
        ) {
          const inner =
            tempSelectedBuildingFoorPlan.regions.innerPolygons.features.map(
              (el) => {
                return { ...el, colorCode: polygonDefaultColor };
              }
            );

          tempSelectedBuildingFoorPlan = updateImmutably(
            tempSelectedBuildingFoorPlan,
            {
              regions: {
                innerPolygons: {
                  features: {
                    $set: inner,
                  },
                },
              },
            }
          );
        }

        dispatchToState({
          type: ReducerActionTypes.SetState,
          payload: {
            selectedBuildingFloorPlan: tempSelectedBuildingFoorPlan,
            regionColorType: colorType,
          },
        });
      }
    },
    [
      ctx.buildingList,
      ctx.selectedPhenoms,
      floorPlanIDStore,
      floorPlanLocationList,
    ]
  );

  useEffect(() => {
    if (regionColorType !== RegionColorSelection.Heatmap) {
      onRegionSwitch(regionColorType ?? RegionColorSelection.Highest);
    } else {
      mapInstance?.eachLayer((layer) => {
        if ((layer as any)._heat) {
          layer.remove();
        }
      });
      onHeatmapSelect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.buildingList.length, ctx.selectedPhenoms.length, floorPlanIDStore]);

  useEffect(() => {
    if (regionColorType !== RegionColorSelection.Heatmap && mapInstance) {
      mapInstance.eachLayer((layer) => {
        if ((layer as any)._heat) {
          layer.remove();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionColorType]);

  const onHeatmapSelect = () => {
    if (
      mapInstance &&
      floorPlanLocationList.length > 0 &&
      ctx.selectedPhenoms.length === 1
    ) {
      floorPlanLocationList.forEach((el) => {
        if (el.x && el.y) {
          const result = getSelectedPhenomDetails(el.locationID);

          const markers = result?.markers;
          const sensorValue = result?.sensorValue;

          if (markers && sensorValue) {
            let colorCode = '';
            for (let index = 0; index <= markers.length; index++) {
              if (sensorValue <= markers[index].value) {
                colorCode = markers[index].colour;
                break;
              }
            }

            if (colorCode) {
              Leaflet.heatLayer([[el.y, el.x]] as any, {
                minOpacity: 1,
                gradient: getHeatmapGradient(colorCode),
                radius: 100,
              }).addTo(mapInstance);
            }
          }
        }
      });
    }
  };

  const handleMap = (map: Map) => {
    if (map) {
      state.mapBounds && map.fitBounds(state.mapBounds);

      dispatchToState({
        type: ReducerActionTypes.SetState,
        payload: { mapInstance: map },
      });
    }
  };

  const onRegionSelectionChange = (e: RadioChangeEvent) => {
    if (RegionColorSelection.Heatmap === e.target.value) {
      dispatchToState({
        type: ReducerActionTypes.SetState,
        payload: { regionColorType: e.target.value },
      });
      onHeatmapSelect();
    } else {
      onRegionSwitch(e.target.value);
    }
  };

  const mapImage = `data:image/svg+xml,${encodeURIComponent(
    mapDetails.image ?? ''
  )}`;

  return (
    <Fragment>
      <MapContainer
        tap={false}
        attributionControl={false}
        minZoom={-5}
        zoom={-5}
        maxZoom={19}
        center={mapCenter}
        bounds={mapBounds}
        crs={CRS_CUSTOM_SCALE}
        className={`leaflet-map ${cssStyles.map}`}
        whenCreated={handleMap}
        boundsOptions={{
          padding: [0, 0],
        }}
        maxBounds={mapBounds}
        zoomSnap={0}
        zoomDelta={0.5}
        // @ts-ignore
        whenReady={(e) => e?.target?.fitBounds(mapBounds)}>
        {mapDetails.image && mapBounds && (
          <ImageOverlay bounds={mapBounds} url={mapImage} />
        )}

        <Radio.Group
          onChange={onRegionSelectionChange}
          value={regionColorType}
          className={cssStyles.radioGroup}>
          <Radio value={RegionColorSelection.Highest}>Highest</Radio>
          <Radio value={RegionColorSelection.Lowest}>Lowest</Radio>
          <Radio value={RegionColorSelection.Heatmap}>Heatmap</Radio>
        </Radio.Group>

        {selectedBuildingFloorPlan.regions?.outerPolygon?.features &&
          selectedBuildingFloorPlan.regions.outerPolygon.features.length > 0 &&
          selectedBuildingFloorPlan.regions.outerPolygon.features.map(
            (el, idx) => {
              return <Polygon key={idx} data={el} />;
            }
          )}

        {selectedBuildingFloorPlan.regions?.innerPolygons?.features &&
          selectedBuildingFloorPlan.regions.innerPolygons.features.length > 0 &&
          selectedBuildingFloorPlan.regions.innerPolygons.features.map(
            (el, idx) => {
              return <Polygon key={idx} data={el} />;
            }
          )}

        <CircleMapMarker
          floorPlanLocations={floorPlanLocationList}
          selectedLocationID={selectedLocationID}
          onMarkerClick={onMarkerClick}
        />
      </MapContainer>
    </Fragment>
  );
};

export default PolygonMap;

const reducer = (state: StateType, action: ReducerHookActionType) => {
  switch (action.type) {
    case ReducerActionTypes.SetState: {
      const updatedState = updateImmutably(state, {
        $merge: { ...action.payload },
      });
      return { ...updatedState };
    }

    default:
      return { ...state };
  }
};

const Polygon = ({ data }: { data: GeoJSONFeaturesType }) => {
  return (
    <Fragment>
      {data && <GeoJSON data={data} pathOptions={{ color: data.colorCode }} />}
    </Fragment>
  );
};

function getPolygonRegion({
  selectedBuildingFloor,
  selectedPhenom,
  floorPlanLocationList,
  regionsType,
  colorType,
}: {
  selectedBuildingFloor: BuildingFloorPlanType;
  floorPlanLocationList: FloorPlanLocationListType[];
  selectedPhenom: string;
  regionsType: 'outerPolygon' | 'innerPolygons';
  colorType: RegionColorSelection;
}) {
  const tempRegions = selectedBuildingFloor?.regions?.[
    regionsType
  ]?.features?.map((feature) => {
    let tempFeature = { ...feature };
    const { locationIDs } = tempFeature;
    const tempFloorPlanLocations = floorPlanLocationList.filter((location) => {
      let allPhenomsValues = location.latestData.Dnum;
      if (
        locationIDs?.find((id) => id === location.locationID) &&
        allPhenomsValues &&
        allPhenomsValues[selectedPhenom.toUpperCase()] !== undefined
      ) {
        return location;
      }
      return null;
    });

    if (tempFloorPlanLocations.length > 0) {
      const tempFloorPlanLocations2: any = tempFloorPlanLocations.map(
        (loc: any) => {
          return {
            ...loc,
            [selectedPhenom]: loc.latestData.Dnum[selectedPhenom],
          };
        }
      );

      let transformed = getHighestValArrObj(
        tempFloorPlanLocations2,
        selectedPhenom
      );
      if (colorType === RegionColorSelection.Lowest) {
        transformed = getLowestValArrObj(
          tempFloorPlanLocations2,
          selectedPhenom
        );
      }
      const selectedSensorSpec: any = transformed?.sensorSpecs.find(
        (sensor: any) =>
          sensor.shortName.toUpperCase() === selectedPhenom.toUpperCase()
      );
      if (selectedSensorSpec && transformed) {
        for (
          let index = 0;
          index <= selectedSensorSpec.markers.length;
          index++
        ) {
          if (
            transformed[selectedPhenom] <=
            selectedSensorSpec.markers[index].value
          ) {
            tempFeature = updateImmutably(tempFeature, {
              colorCode: {
                $set: selectedSensorSpec.markers[index].colour,
              },
            });
            break;
          }
        }
      }
    } else {
      tempFeature = updateImmutably(tempFeature, {
        colorCode: { $set: polygonDefaultColor },
      });
    }
    return { ...tempFeature };
  });

  return tempRegions;
}

interface GradientType {
  [k: number]: string;
}

const getHeatmapGradient = (colorCode: string): GradientType => {
  if (colorCode === ColorCodeDetails.Green) {
    return { 0.9: 'green', 0.001: 'yellow', 0.0: 'red' };
  } else if (colorCode === ColorCodeDetails.Amber) {
    return { 0.9: 'yellow', 0.001: 'green', 0.0: 'red' };
  } else {
    return { 0.9: 'red', 0.001: 'yellow', 0.0: 'green' };
  }
};
