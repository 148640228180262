import { Button, Col, Input, message, Row, Table } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Modal from 'antd/lib/modal/Modal';
import { AxiosResponse } from 'axios';
import {
  Dispatch,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaClipboard, FaTrashAlt } from 'react-icons/fa';
import update from 'immutability-helper';

import { userApi } from '../../../api-services/api-list';

import useHttp from '../../../hooks/use-http';
import {
  AntdTableColumnsType,
  ApiResponseDataType,
  ReducerHookActionType,
} from '../../../@types';
import {
  ApiKeysModalStateType,
  apiKeysModalInitState,
  apiKeysModalReducer,
} from '../helpers';
import { ApiKeysListType } from '../../../@types';
import { v4 } from 'uuid';
import { handleNotification } from '../../../utils/notification-handler';
import { ReducerActionTypes } from '../../../shared/helpers';

type PropsType = {
  showModal: boolean;
  handleModal: () => void;
  storeToken?: string;
  userID?: string;
};

const ApiKeysModal = ({
  showModal,
  handleModal,
  storeToken,
  userID,
}: PropsType) => {
  const screens = useBreakpoint();
  const { isLoading, sendRequest } = useHttp(true);
  const [state, dispatchToState]: [
    state: ApiKeysModalStateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(apiKeysModalReducer, apiKeysModalInitState);

  const { apiKeysList, showAddModal, newApiKey } = state;

  useEffect(() => {
    if (storeToken && userID) {
      const { url, method, contentType } = userApi.getApiKeys(undefined, {
        userID,
      });

      const handleFetchedData = (
        response: AxiosResponse<ApiResponseDataType>
      ) => {
        const result: ApiKeysListType[] = response?.data.data;
        if (result && result.length > 0) {
          const tempData = result.map((el) => {
            return {
              ...el,
              uuid: v4(),
              hiddenValue: 'XXXX-XXXX-XXXX-XXXX-XXXX',
              showValue: false,
              copyStatus: false,
            };
          });

          dispatchToState({
            type: ReducerActionTypes.SetState,
            payload: { apiKeysList: tempData },
          });
        }
      };

      sendRequest({ url, method, contentType, storeToken }, handleFetchedData);
    }
  }, [sendRequest, storeToken, userID]);

  const handleCopyToClipboard = useCallback(
    (id: string) => {
      const tempData = apiKeysList.map((el) => {
        if (el.id === id) {
          return { ...el, copyStatus: true };
        }
        return { ...el, copyStatus: false };
      });

      dispatchToState({
        type: ReducerActionTypes.SetState,
        payload: { apiKeysList: tempData },
      });
    },
    [apiKeysList]
  );

  const handlePeep = useCallback(
    (id: string) => {
      const tempData = apiKeysList.map((el) => {
        if (el.id === id) {
          return { ...el, showValue: !el.showValue };
        }
        return { ...el };
      });

      dispatchToState({
        type: ReducerActionTypes.SetState,
        payload: { apiKeysList: tempData },
      });
    },
    [apiKeysList]
  );

  const handleDelete = useCallback(
    (id: string) => {
      if (userID && storeToken) {
        const { url, method, contentType } = userApi.deleteApiKeys(undefined, {
          userID,
          keyID: id,
        });

        const handleResponse = (
          response: AxiosResponse<ApiResponseDataType>
        ) => {
          const result = response.data;

          if (result) {
            handleNotification('success', result.data);

            const tempData = apiKeysList.filter((el) => el.id !== id);

            dispatchToState({
              type: ReducerActionTypes.SetState,
              payload: { apiKeysList: tempData },
            });
          }
        };

        sendRequest(
          {
            url,
            method,
            contentType,
            storeToken,
          },
          handleResponse
        );
      }
    },
    [apiKeysList, sendRequest, storeToken, userID]
  );

  const columns: AntdTableColumnsType<ApiKeysListType>[] = useMemo(
    () => [
      {
        title: 'Key Name',
        key: 'id',
        dataIndex: 'id',
        sorter: (a: ApiKeysListType, b: ApiKeysListType) =>
          a.id.length - b.id.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Key Value',
        key: 'token',
        render: (text, record: ApiKeysListType, index) => (
          <Fragment>
            {record.id && (
              <Row align="middle" justify="space-between">
                <Col
                  style={{ fontFamily: `Courier, monospace` }}
                  onMouseEnter={() => handlePeep(record.id)}
                  onMouseLeave={() => handlePeep(record.id)}>
                  {record.showValue ? record.token : record.hiddenValue}
                </Col>
                <Col>
                  <CopyToClipboard text={record.token}>
                    <FaClipboard
                      onClick={() => handleCopyToClipboard(record.id)}
                      style={{
                        cursor: 'pointer',
                        color: record.copyStatus ? '#50B93F' : '',
                      }}
                    />
                  </CopyToClipboard>
                </Col>
              </Row>
            )}
          </Fragment>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (record: any) => (
          <Fragment>
            {record.id && (
              <FaTrashAlt
                onClick={() => handleDelete(record.id)}
                style={{
                  cursor: 'pointer',
                  color: 'red',
                }}
              />
            )}
          </Fragment>
        ),
      },
    ],
    [handleCopyToClipboard, handleDelete, handlePeep]
  );

  const onAddModalOkay = useCallback(() => {
    if (!newApiKey) {
      return message.error('API Key is required!');
    }

    if (userID && storeToken) {
      const { url, method, contentType } = userApi.postApiKeys(undefined, {
        userID,
      });

      const handleResponse = (response: AxiosResponse<ApiResponseDataType>) => {
        const result = response.data;
        if (result) {
          const data = result.data;
          handleNotification('success', result.data);

          const tempData = update(apiKeysList, {
            $push: [
              {
                id: data.id,
                token: data.token,
                uuid: v4(),
                hiddenValue: 'XXXX-XXXX-XXXX-XXXX-XXXX',
                showValue: false,
                copyStatus: false,
              },
            ],
          });

          dispatchToState({
            type: ReducerActionTypes.SetState,
            payload: { apiKeysList: tempData, showAddModal: false },
          });
        }
      };

      sendRequest(
        {
          url,
          method,
          contentType,
          storeToken,
          data: { id: newApiKey },
        },
        handleResponse
      );
    }
  }, [apiKeysList, newApiKey, sendRequest, storeToken, userID]);

  const handleAddModal = () => {
    dispatchToState({ type: ReducerActionTypes.SetAddApiKeyModal });
  };

  const onAddModalInputChange = (value: string) => {
    dispatchToState({
      type: ReducerActionTypes.SetState,
      payload: { newApiKey: value },
    });
  };

  return (
    <Fragment>
      <Modal
        visible={showModal}
        onCancel={handleModal}
        closable={false}
        width={800}
        footer={
          <Row justify="space-between">
            <Col>
              <Button type="primary" htmlType="button" onClick={handleAddModal}>
                Add Key
              </Button>
            </Col>
            <Col>
              <Button htmlType="button" onClick={handleModal}>
                Done
              </Button>
            </Col>
          </Row>
        }>
        <Row>
          <Col xs={24}>
            <Table
              bordered
              scroll={{ x: true }}
              dataSource={apiKeysList}
              loading={{ size: 'large', spinning: isLoading }}
              columns={columns}
              pagination={{
                pageSize: 10,
                showSizeChanger: false,
                total: apiKeysList.length,
                size: screens.xs ? undefined : 'default',
                simple: screens.xs ? true : false,
              }}
              rowKey={'uuid'}
            />
          </Col>
        </Row>
      </Modal>

      {showAddModal && (
        <AddApiKeyModal
          showModal={showAddModal}
          handleModal={handleAddModal}
          onOkay={onAddModalOkay}
          onInputChange={onAddModalInputChange}
          newApiKey={newApiKey}
        />
      )}
    </Fragment>
  );
};

export default ApiKeysModal;

const AddApiKeyModal = ({
  showModal,
  handleModal,
  onOkay,
  onInputChange,
  newApiKey,
}: {
  showModal: boolean;
  handleModal: () => void;
  onOkay: () => void;
  onInputChange: (value: string) => void;
  newApiKey: string;
}) => {
  return (
    <Modal
      visible={showModal}
      onCancel={handleModal}
      width={400}
      onOk={onOkay}
      title="Add API Key"
      closable={false}>
      <Row>
        <Col>
          <Input
            name="newApiKey"
            onChange={(event) => onInputChange(event.currentTarget.value)}
            value={newApiKey}
          />
        </Col>
      </Row>
    </Modal>
  );
};
