import { Fragment, Component } from 'react';
import SpeedoMeter, {
  Props as SpeedoMeterPropsType,
} from 'react-d3-speedometer';
import { MarkersListType } from '../../@types';

type PropsType = {
  needleValue?: number;
  minScale?: number;
  maxScale?: number;
  actualValue?: number;
  markers?: MarkersListType[];
  // amberPoint?: number;
  // redPoint?: number;
  units?: string;
  shortName?: string;
  speedoMeterProps?: SpeedoMeterPropsType;
  shouldForceRender?: boolean;
};

type StateType = {
  shouldUpdate: boolean;
};

class ReactSpeedoMeter extends Component<PropsType, StateType> {
  state = { shouldUpdate: false };

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    const { speedoMeterProps, shouldForceRender } = this.props;
    const { shouldUpdate } = this.state;

    if (
      (prevProps?.speedoMeterProps?.width !== speedoMeterProps?.width ||
        prevProps?.speedoMeterProps?.height !== speedoMeterProps?.height) &&
      prevState.shouldUpdate === false
    ) {
      this.setState({ shouldUpdate: true });
    }

    if (
      prevProps?.speedoMeterProps?.width === speedoMeterProps?.width &&
      prevProps?.speedoMeterProps?.height === speedoMeterProps?.height &&
      prevState.shouldUpdate === true
    ) {
      this.setState({ shouldUpdate: false });
    }

    if (shouldForceRender && !shouldUpdate) {
      this.setState({ shouldUpdate: true });
    }
  }

  render() {
    const {
      needleValue,
      minScale,
      maxScale,
      actualValue,
      units,
      markers,
      // shortName,
      // speedo meter props
      speedoMeterProps,
    } = this.props;

    const { shouldUpdate } = this.state;

    let customSegmentStops = [minScale ?? 0];
    let segmentColors: string[] = [];
    if (markers) {
      markers.forEach((el) => {
        segmentColors.push(el.colour);
        if (el.value !== maxScale) {
          customSegmentStops.push(el.value);
        }
      });
      customSegmentStops.push(maxScale ?? 100);
    }

    return (
      <Fragment>
        <SpeedoMeter
          //speedo meter props
          forceRender={shouldUpdate}
          needleColor={speedoMeterProps?.needleColor ?? 'black'}
          ringWidth={
            speedoMeterProps?.ringWidth ??
            (speedoMeterProps?.width ? speedoMeterProps?.width / 12 : 12)
          }
          needleHeightRatio={0.9}
          textColor={speedoMeterProps?.textColor ?? 'rgba(0,0,0,.65)'}
          width={speedoMeterProps?.width ?? 140}
          height={speedoMeterProps?.height ?? 110}
          valueTextFontSize={
            speedoMeterProps?.valueTextFontSize ??
            `${
              speedoMeterProps?.width
                ? speedoMeterProps?.width / 32 > 12
                  ? speedoMeterProps?.width / 32
                  : 12
                : 12
            }px`
          }
          labelFontSize={speedoMeterProps?.labelFontSize ?? '0px'}
          segmentColors={
            speedoMeterProps?.segmentColors ?? [...segmentColors] ?? [
              'rgb(61,204,91)',
              'rgb(239,214,19)',
              'rgb(255,84,84)',
            ]
          }
          // value props
          value={needleValue}
          minValue={minScale}
          maxValue={maxScale}
          currentValueText={`${actualValue}${units}`}
          // customSegmentStops={[minScale, amberPoint, redPoint, maxScale]}
          customSegmentStops={customSegmentStops}
        />
      </Fragment>
    );
  }
}

export default ReactSpeedoMeter;
