import { FloorPlanMapDetailsType } from '@airsensa/react-components/dist/@types';
import update from 'immutability-helper';
import { createContext } from 'react';

import { ReducerHookActionType } from '../../../@types';

import {
  BuildingFloorPlanType,
  BuildingListType,
  FloorPlanLocationListType,
  LatLngCoordinatesType,
} from '../../../@types';
import { ReducerActionTypes } from '../../../shared/helpers';

export const initState: StateType = {
  loading: true,
  floorPlanLocationList: [],
  initFloorPlanLocationsList: [],
  mapDetails: {},
  phenomCollection: [],

  selectedPhenoms: [],
  selectedAirQualities: [],

  selectedLocationID: '',
  isFloorplanChanged: false,

  buildingList: [],
};

export type StateType = {
  loading: boolean;
  floorPlanLocationList: FloorPlanLocationsWithPosition[];
  initFloorPlanLocationsList: FloorPlanLocationsWithPosition[];
  mapDetails: FloorPlanMapDetailsType;
  phenomCollection: string[];

  selectedPhenoms: string[];
  selectedAirQualities: string[];

  selectedLocationID: string;
  isFloorplanChanged: boolean;

  buildingList: BuildingListType[];
};

export const reducer = (state: StateType, action: ReducerHookActionType) => {
  switch (action.type) {
    case ReducerActionTypes.SetState: {
      const updatedState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...updatedState };
    }

    case ReducerActionTypes.SetLocationID: {
      let locID = action.payload?.selectedLocationID;
      if (locID === state.selectedLocationID) {
        locID = '';
      }
      const updatedState = update(state, {
        selectedLocationID: { $set: locID },
      });
      return { ...updatedState };
    }

    default:
      return { ...state };
  }
};

export const airQualityFilterValues = {
  red: 'Red',
  amber: 'Amber',
  green: 'Green',
};

export type FloorPlanLocationsWithPosition = FloorPlanLocationListType & {
  colorCode?: string;
  colorName?: string;
  position?: LatLngCoordinatesType;
  outerPolygonLatLng?: LatLngCoordinatesType[];
  innerPolygonsLatLng?: LatLngCoordinatesType[][];
};

export interface FloorPlanContextType {
  selectedPhenoms: string[];
  buildingList: BuildingListType[];
}

export const FloorPlanContext = createContext<FloorPlanContextType>({
  selectedPhenoms: [],
  buildingList: [],
});

export const getFloorPlanFromBuildings = (
  floorPlanID: string,
  buildings: BuildingListType[]
): BuildingFloorPlanType | undefined => {
  let selectedFloorPlan: BuildingFloorPlanType | undefined;
  const selectedBuilding = buildings.find((el) =>
    el.floorplans.find((elem) => elem.floorplanID === floorPlanID)
  );
  if (selectedBuilding) {
    selectedFloorPlan = selectedBuilding.floorplans.find(
      (el) => el.floorplanID === floorPlanID
    );
    return selectedFloorPlan;
  }

  return selectedFloorPlan;
};
