import update from 'immutability-helper';

import { LocationListType } from '../../../@types';
import {
  ChartListType,
  GaugeListType,
  ReducerHookActionType,
} from '../../../@types';
import { ReducerActionTypes } from '../../../shared/helpers';

export const initState: StateType = {
  loading: true,
  locationDetails: {},
  gaugeList: [],
  gaugeDateTime: '',
  chartList: [],
};

export type StateType = {
  loading: boolean;
  locationDetails: Partial<LocationListType>;
  overallAqiIndex?: number;
  gaugeList: GaugeListType[];
  gaugeDateTime: string;
  chartList: ChartListType[];
};

export const reducer = (state: StateType, action: ReducerHookActionType) => {
  switch (action.type) {
    case ReducerActionTypes.SetState: {
      const updatedState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...updatedState };
    }

    default:
      return { ...state };
  }
};
