export enum ReducerActionTypes {
  SetState = 'SET_STATE',
  SetMapDetails = 'SET_MAP_DETAILS',

  // FloorPlans
  SetLocationID = 'SET_LOCATION_ID',

  // ProfileDetails
  SetChangePass = 'SET_CHANGE_PASS',
  SetApiKeys = 'SET_API_KEYS',
  SetAddApiKeyModal = 'SET_ADD_API_KEY_MODAL',
}
