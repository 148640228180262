import { Col, Row } from 'antd';
import { CSSProperties, Fragment } from 'react';
import { BiCopyright } from 'react-icons/bi';
import AntdCard from '../../../components-shared/AntdCard';
import DownloadModal from '../../../components-shared/DownloadModal';
import { LocationListType, UserDataType } from '../../../@types';

const headerInfoSmallStyle: CSSProperties = {
  fontStyle: 'italic',
  color: '#a1a1d0',
};

function Header({
  loading,
  locationDetails,
  userData,
}: {
  loading: boolean;
  locationDetails: Partial<LocationListType>;
  userData: Partial<UserDataType>;
}) {
  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <AntdCard loading={loading} style={{ width: '100%' }} elevate>
            <Row>
              <Col xs={24}>
                <span
                  style={{
                    color: '#3672f8',
                    fontSize: '1.2rem',
                  }}>{`${locationDetails?.name} - ${locationDetails?.friendlyName}`}</span>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col xs={24} md={20}>
                <span style={{ color: '#3672f8', fontSize: '.8rem' }}>
                  {locationDetails?.description}
                </span>
              </Col>
              <Col>
                <span
                  style={
                    headerInfoSmallStyle
                  }>{`ID: ${locationDetails?.locationID}`}</span>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col xs={24} md={12}>
                <span
                  style={
                    headerInfoSmallStyle
                  }>{`Last contact: ${locationDetails.lastContact}`}</span>
              </Col>
              <Col>
                <span
                  style={
                    headerInfoSmallStyle
                  }>{`SN: ${locationDetails?.deviceID}`}</span>
              </Col>
            </Row>
            <Row>
              <Col
                style={{ display: 'flex', alignItems: 'center' }}
                className="pr-2">
                <BiCopyright size="1.2em" />
              </Col>
              <Col>{locationDetails?.copyright}</Col>
            </Row>

            {locationDetails?.rights?.includes('CanDownload') && (
              <Row className="pt-3">
                <Col>
                  <DownloadModal
                    locationID={locationDetails.locationID || ''}
                    userData={userData}
                  />
                </Col>
              </Row>
            )}
          </AntdCard>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Header;
