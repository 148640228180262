import { Fragment, PureComponent } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Popup } from 'react-leaflet';
import { connect, ConnectedProps } from 'react-redux';
import { Col, Row, Spin, Table } from 'antd';
import moment from 'moment';
import update from 'immutability-helper';

import { updateToken } from '../../../../redux/actions/auth.actions';
import { ReduxStoreType } from '../../../../@types';
import {
  AxiosHttpAllSettledResponsesType,
  LocationListType,
} from '../../../../@types';
import { locationApi } from '../../../../api-services/api-list';
import {
  httpCallAllSettled,
  httpCallErrorHandling,
} from '../../../../api-services/api';
import { locationRoutes } from '../../../../routes/routes-list';

type PhenomDataType = {
  shortName: string;
  longName: string;
  value: string;
};

type PropsType = PropsFromRedux &
  RouteComponentProps & {
    locationId: string;
  };

type StateType = {
  loading: boolean;
  locationLatest: any;
  locationDetails: Partial<LocationListType>;
};

class MapPopup extends PureComponent<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      locationLatest: {},
      loading: true,
      locationDetails: {},
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  handlePopupOpen = async () => {
    const { locationId, userData, updateToken } = this.props;

    if (userData.token) {
      const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
        let stateData: Partial<StateType> = {};
        if (responses.length > 0) {
          if (responses[0].status === 'fulfilled') {
            const data = responses[0].value.data;
            const details = data?.data;
            stateData = update(stateData, {
              locationDetails: { $set: details ?? {} },
            });
            updateToken(data);
          } else {
            httpCallErrorHandling(responses[0]);
          }

          if (responses[1].status === 'fulfilled') {
            const data = responses[1].value.data;
            const latest = data?.data;
            stateData = update(stateData, {
              locationLatest: { $set: latest ?? {} },
            });
            updateToken(data);
          } else {
            httpCallErrorHandling(responses[1]);
          }
        }

        this.handleState({ ...stateData, loading: false });
      };

      const getLocationLatest = locationApi.getLocationLatest(undefined, {
        locationID: locationId,
      });

      const getLocationDetails = locationApi.getLocationDetails(undefined, {
        locationID: locationId,
      });

      httpCallAllSettled({
        requestConfig: [{ ...getLocationDetails }, { ...getLocationLatest }],
        headersConfig: {
          token: userData.token,
        },
        applyData: handleResponses,
      });
    }
  };

  render() {
    const { locationLatest, loading, locationDetails } = this.state;
    const phenomArray: PhenomDataType[] = [];

    if (
      locationDetails &&
      locationDetails.sensorSpecs &&
      locationDetails.sensorSpecs.length > 0 &&
      locationLatest &&
      locationLatest?.latestData &&
      locationLatest?.latestData?.Dnum
    ) {
      locationDetails.sensorSpecs.forEach((item) => {
        for (const key in locationLatest.latestData.Dnum) {
          if (
            key.toString().toUpperCase() ===
            item?.shortName?.toString().toUpperCase()
          ) {
            const listObj = {
              shortName: item.shortName,
              longName: item.longName,
              value: `${locationLatest.latestData.Dnum[key]} ${item.units}`,
            };
            phenomArray.push(listObj);
          }
        }
      });
    }

    let columns: any = [];

    if (phenomArray.length > 0) {
      columns = [
        {
          // title: 'Location ID',
          key: 'longName',
          dataIndex: 'longName',
        },
        {
          // title: 'Location Name',
          dataIndex: 'value',
          key: 'value',
        },
      ];
    }

    return (
      <Fragment>
        <Popup onOpen={this.handlePopupOpen}>
          <Row justify="center" align="middle">
            <Col>
              <h2>
                <Link
                  to={{
                    pathname: locationRoutes.dashboard({
                      locationID: locationDetails.locationID ?? '',
                    }),
                  }}>
                  {locationDetails.name}
                </Link>
              </h2>
            </Col>
          </Row>

          <Row justify="center" align="middle" className="py-2">
            <Col>
              Last Contact:{' '}
              {moment
                .utc(locationDetails.lastContact)
                .format('YYYY-MM-DD HH:mm:ss')}
            </Col>
          </Row>

          {loading ? (
            <Row justify="center" align="middle">
              <Col>
                <Spin size="small" />
              </Col>
            </Row>
          ) : phenomArray.length > 0 ? (
            <Table
              rowKey={(record) => record['shortName']}
              bordered={false}
              columns={columns}
              dataSource={phenomArray}
              pagination={false}
              showHeader={false}
            />
          ) : (
            <span>No data.</span>
          )}
        </Popup>
      </Fragment>
    );
  }
}

function mapStateToProps(state: ReduxStoreType) {
  return {
    userData: state.auth.userData,
  };
}

const mapDispatch = {
  updateToken: updateToken,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(MapPopup));
