import { Col, Row } from 'antd';
import { Fragment } from 'react';
import AntdCard from '../../../components-shared/AntdCard';
import ReactSpeedoMeter from '../../../components-shared/ReactSpeedoMeter';
import { GaugeListType } from '../../../@types';
import { LocationListType } from '../../../@types';
import { getTwoDecimalPoints } from '../../../shared/helpers';

type PropsType = {
  loading: boolean;
  gaugeList: GaugeListType[];
  locationDetails: Partial<LocationListType>;
};

function Gauge({ loading, gaugeList, locationDetails }: PropsType) {
  return (
    <Fragment>
      <Row className="gauges-root">
        <Col xs={24}>
          <AntdCard
            loading={loading}
            bodyStyle={{ padding: 12, height: '35vh' }}
            className="gauges-card"
            elevate>
            {locationDetails?.name && (
              <Row justify="center" className="name">
                <Col className="text-center">
                  <h2>{locationDetails.name}</h2>
                </Col>
              </Row>
            )}

            {locationDetails.lastContact && (
              <Row justify="center" className="contact">
                <Col style={{ fontStyle: 'italic', color: '#a1a1d0' }}>
                  Last Contact: {locationDetails.lastContact}
                </Col>
              </Row>
            )}

            <Row wrap={false} className="gauges">
              {gaugeList &&
                gaugeList.length > 0 &&
                gaugeList.map((el) => {
                  const actualValue = getTwoDecimalPoints(el.actualValue);
                  return (
                    <Fragment key={el.shortName}>
                      <Col className="pb-3 text-center" flex={1}>
                        <ReactSpeedoMeter {...el} actualValue={actualValue} />
                        <span>{el.longName}</span>
                      </Col>
                    </Fragment>
                  );
                })}
            </Row>
          </AntdCard>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Gauge;
