import { Fragment, PureComponent, ReactNode } from 'react';
import {
  MapContainer,
  Marker,
  Popup,
  ImageOverlay,
  PopupProps,
  MarkerProps,
  MapContainerProps,
} from 'react-leaflet';
import { Col, Row } from 'antd';
import * as Leaflet from 'leaflet';

import 'leaflet/dist/leaflet.css';
import { getLeafletIcon } from '../../utils/leaflet-icon';
import {
  FloorPlanMapDetailsType,
  MapBoundsType,
  MapCoordinatesType,
} from '@airsensa/react-components/dist/@types';
import { CRS_CUSTOM_SCALE, getMapBoundsAndCenter } from '../../shared/helpers';

interface PropsType {
  mapDetails: FloorPlanMapDetailsType;
  zoom?: number;
  markerRender?: ReactNode;
  popupRender?: ReactNode;
  popupContentRender?: (coordinates: MapCoordinatesType) => ReactNode;
  markerIconColor?: 'amber' | 'red' | 'green' | 'grey' | 'blue';
  popupProps?: Partial<PopupProps>;
  markerProps?: Partial<MarkerProps>;
  mapContainerProps?: Partial<MapContainerProps>;
  onMapMount?: (params: {
    map: Leaflet.Map;
    center: MapCoordinatesType;
  }) => void;
  coordinates?: MapCoordinatesType;
  polygonRender?: ReactNode;
}

interface StateType {
  mapBounds?: MapBoundsType;
  mapInstance?: Leaflet.Map;
  mapCenter: MapCoordinatesType;
}

class FloorPlanMap extends PureComponent<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    const { mapDetails } = props;

    let bounds: MapBoundsType | undefined;
    let center: MapCoordinatesType | undefined;

    if (mapDetails.height && mapDetails.width) {
      const result = getMapBoundsAndCenter({
        height: mapDetails.height,
        width: mapDetails.width,
      });

      bounds = result.bounds;
      center = result.centre;
    }

    this.state = {
      mapBounds: bounds,
      mapInstance: undefined,
      mapCenter: center ?? [0, 0],
    };
  }

  handleMap = (map: Leaflet.Map) => {
    this.state.mapBounds && map.fitBounds(this.state.mapBounds);
    const { onMapMount } = this.props;
    const { mapCenter } = this.state;
    if (map) {
      onMapMount?.({ map, center: mapCenter });
    }
  };

  render() {
    const {
      mapDetails,
      popupRender,
      popupContentRender,
      markerIconColor,
      markerProps,
      popupProps,
      mapContainerProps,
      polygonRender,
      coordinates,
      zoom,
      markerRender,
    } = this.props;
    const { mapBounds, mapCenter } = this.state;
    const mapImage = `data:image/svg+xml,${encodeURIComponent(
      mapDetails.image || ''
    )}`;

    const mapCoordinates = coordinates ?? [0, 0];

    if (mapContainerProps?.className) {
      mapContainerProps.className = `floor-plan-map ${mapContainerProps.className}`;
    }

    return (
      <Fragment>
        <MapContainer
          attributionControl={false}
          // minZoom={-5}
          // zoom={-5}
          maxZoom={19}
          center={mapCenter}
          // bounds={mapBounds}
          tap={false}
          minZoom={zoom ?? -1}
          zoom={zoom ?? -1}
          bounds={mapBounds}
          maxBounds={mapBounds}
          boundsOptions={{
            padding: [0, 0],
          }}
          zoomSnap={0}
          zoomDelta={0.5}
          // @ts-ignore
          whenReady={(e) => e?.target?.fitBounds(mapBounds)}
          // crs={Leaflet.CRS.Simple}
          crs={CRS_CUSTOM_SCALE}
          whenCreated={this.handleMap}
          {...mapContainerProps}>
          {mapDetails.image && mapBounds && (
            <ImageOverlay bounds={mapBounds} url={mapImage} />
          )}

          {polygonRender}

          {markerRender ? (
            markerRender
          ) : (
            <Marker
              {...markerProps}
              position={mapCoordinates}
              icon={getLeafletIcon(markerIconColor || 'blue')}>
              {popupRender ? (
                popupRender
              ) : (
                <Popup {...popupProps}>
                  {popupContentRender ? (
                    popupContentRender(mapCoordinates)
                  ) : (
                    <Row>
                      <Col xs={24}>Latitude: {mapCoordinates[0]}</Col>
                      <Col xs={24}> Longitude: {mapCoordinates[1]} </Col>
                    </Row>
                  )}
                </Popup>
              )}
            </Marker>
          )}
        </MapContainer>
      </Fragment>
    );
  }
}

export default FloorPlanMap;
