import { ReduxActionPayloadType } from '../../@types';
import {
  SAVE_FILTER_CHECKBOX_DATA,
  SAVE_FLOOR_PLAN_DETAILS,
  SEARCH_DATA,
  SETTINGS_DIALOG,
} from '../actionTypes/location.types';

export function saveFilterCheckboxData({
  filterCheckBoxData,
}: ReduxActionPayloadType) {
  return {
    type: SAVE_FILTER_CHECKBOX_DATA,
    payload: { filterCheckBoxData },
  };
}

export function saveSearchData({ searchData }: ReduxActionPayloadType) {
  return {
    type: SEARCH_DATA,
    payload: { searchData },
  };
}

export function saveSettingsDialog({
  settingsDialogFormData,
}: ReduxActionPayloadType) {
  return {
    type: SETTINGS_DIALOG,
    payload: { settingsDialogFormData },
  };
}

export function saveFloorPlanDetails({
  floorPlanID,
  assetID,
  buildingID,
}: {
  floorPlanID?: string;
  assetID?: string;
  buildingID?: string;
}) {
  return {
    type: SAVE_FLOOR_PLAN_DETAILS,
    payload: {
      floorPlanID,
      assetID,
      buildingID,
    },
  };
}
